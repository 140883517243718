<template>
  <form
    :class="['bsc-search-box', 'search-box-regional', isRoundSearch ? 'search-box-round' : '']"
    @submit.prevent="handleSearch"
  >
    <section
      v-show="!showInput"
      class="search-box"
      tabindex="0"
      hidefocus="true"
      @focus="handleSearchBoxFocus">

      <!-- HK icon -->
      <Icon
        name="sui_icon_search_trends_16px"
        size="16px"
        color="#9462FF"
        class=""
        :style="{
          display: ['trendword', 'trendstore'].includes(searchData.wordLabel?.labelType || '')
            ? 'inline-block'
            : 'none',
        }"
      />
      <!-- trend icon -->
      <i
        v-show="searchData.wordLabel?.labelType === 'trend'"
        class="search-trend-icon search-tag-icon"
      >
      </i>
      <!-- hot icon -->
      <Icon
        class="search-trend-icon"
        name="sui_icon_hot_12px"
        size="16px"
        color="#F86262"
        :style="{ display: searchData.wordLabel?.labelType === 'hot' ? 'inline-block' : 'none' }"
      />

      <p class="search-term">
        <span
          v-if="searchData.word"
          :class="{
            'hk-tag': ['trendword', 'trendstore'].includes(searchData.wordLabel?.labelType || '') && searchData.word
          }"
        >{{ searchData.word }}</span>
        <span
          v-else
          class="empty"> {{ language.SHEIN_KEY_PC_20094 }} </span>
      </p>

      <span
        v-show="['sold', 'like'].includes(searchData.wordLabel?.labelType || '') && searchData.wordLabel?.labelFront"
        class="search-label-front-wrapper"
      >
        <i class="search-label-front">{{ searchData.wordLabel?.labelFront }}</i>
      </span>

      <img
        v-show="['choiceword'].includes(searchData.wordLabel?.labelType)"
        :src="searchData.wordLabel?.icon"
        height="12"
        alt="choice"
        class="icon-choice"
      />
    </section>

    <input
      v-show="showInput"
      ref="inputRef"
      v-model="currentWord.keywords"
      class="search-input"
      name="header-search"
      type="search"
      :placeholder="searchData.word || language.SHEIN_KEY_PC_20094"
      autocomplete="off"
      @focus="handleFocus"
      @blur="handleBlur"
      @keydown="handleKeyDown($event)"
    />

    <button
      v-show="currentWord.keywords"
      type="button"
      class="clear-button"
      @click.stop="handleClear">
      <Icon
        name="sui_icon_cleanall_16px"
        size="16px"
        style="line-height: 1"
        color="var(--bsc-search-clear-color, #959595)"
      />
    </button>

    <button
      ref="searchButtonRef"
      type="button"
      :class="['search-button', disabledSearchButton ? 'disabled' : '']"
      @mousedown="handleSearch">
      <sui_icon_nav_search_24px
        size="24px"
        color="var(--bsc-search-icon-color, #fff)"
      />
    </button>

    <transition name="sui-animation__selectmenu">
      <section
        v-show="isInputFocused"
        class="search-content"
      >
        <div
          v-if="associationNum"
          class="association-words-wrapper"
        >
          <AssociationNew
            :correction-num="correctionNum"
            :popular-num="popularNum"
            :correction="correctionStr"
            :association-words="associationWords"
            :item-active-index="status.itemActiveIndex"
            :language="language"
            @go-search-page="goSearchPage"
            @on-item-active-index="handleItemActiveIndex"
          />
        </div>
        <div
          v-else-if="historyHotNum"
          class="history-words"
        >
          <HistoryHotWords
            :history-words="historyWords"
            :hot-words="hotWords"
            :hot-images="hotImages"
            :is-big-search="isBigSearch"
            :show-clear-button="showClearButton"

            @goSearchPage="goSearchPage"
            @cleanHistoryWord="handleCleanHistoryWord"
            @closeClearBtn="handleCloseClearBtn"
            @clearCurSearchWord="handleClearCurSearchWord"
            @clickHistoryWords="handleClickHistoryWords"
          />
        </div>
      </section>
    </transition>
  </form>
</template>

<script name="DefaultSearchBox" setup lang="ts">
import { ref, provide, onMounted, nextTick, computed, watch, getCurrentInstance, watchEffect } from 'vue'
import type { SearchTerm } from './SearchTermType'
import { getQueryString, htmlDecode, stringifyQueryString, debounce } from '@shein/common-function'

// 数据源:
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../common/dataSource'
import type { AS_SearchBox, DS_SearchBox } from '../../../../types'

// 埋点源:
import { useAnalysis } from '../../../../common/analysisSource'

import {
  Icon,
  sui_icon_nav_search_24px,
} from '@shein-aidc/icon-vue3'
import { createIntervalObservable, Subscription } from '../../utils/rx'
import AssociationNew from '../AssociationList/AssociationNew.vue'
import HistoryHotWords from '../HistoryHotWords/HistoryHotWords.vue'
import { initHistory, isObject } from '../../utils/historyWords'
import { getSearchSourceBySearchType } from '../../utils/const'

const appConfigs = useAppConfigs()

/**
 * language:
 * SHEIN_KEY_PC_20094: 'Search',
 * SHEIN_KEY_PC_16957: 'search {0} in {1}',
 * SHEIN_KEY_PC_16956: '{0} in {1}',
 * SHEIN_KEY_PC_30767: 'Trends',
 * SHEIN_KEY_PC_28049: 'Recent',
 * SHEIN_KEY_PC_16870: 'Did you mean',
 * SHEIN_KEY_PC_15811: 'Category',
 * SHEIN_KEY_PC_16655: 'Popular',
 */

type FetchSearchTermsParams = Partial<{
  goods_id: string
  word_type: number
  cat_id: string
  select_id: string
  list_scene: string
  scene: string
}>

type DefaultSearchBoxProps = {
  lang?: string
  siteUID?: string
  language?: any
  isBigSearch?: boolean
  isRoundSearch?: boolean
  isCorrectionRule?: boolean
  isNewSuggestUI?: boolean
  searchTerms?: SearchTerm[]
  getTrackData?: (params: any) => any

  // 数据源
  fetchAssociationWordsParams?: any
  fetchSearchTermsParams?: FetchSearchTermsParams,
  fsData?: DS_SearchBox.FS_DATA
  dataSource?: DS_SearchBox.DataSource
  analysisSource?: AS_SearchBox.AnalysisSource
}

const props = withDefaults(defineProps<DefaultSearchBoxProps>(), {
  lang: 'us',
  siteUID: 'us',
  language: () => ({
    SHEIN_KEY_PC_20094: 'Search',
    SHEIN_KEY_PC_16957: 'search {0} in {1}',
    SHEIN_KEY_PC_16956: '{0} in {1}',
    SHEIN_KEY_PC_30767: 'Trends',
    SHEIN_KEY_PC_28049: 'Recent',
    SHEIN_KEY_PC_16870: 'Did you mean',
    SHEIN_KEY_PC_15811: 'Category',
    SHEIN_KEY_PC_16655: 'Popular',
  }),
  isBigSearch: true,
  isCorrectionRule: false,
  // 控制是否为椭圆搜索框
  isRoundSearch: false,

  searchTerms: () => [],
  // 埋点源
  getTrackData: () => {
    return {}
  },

  fetchAssociationWordsParams: {},
})

const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)
// const { vTap, vExpose, triggerNotice } = analysisInstance

const apis = getSource({ appConfigs, dataSource: props.dataSource })

type SearchTermsEmits = {
  (e: 'resolveSearchTerms', keywords: Partial<SearchTerm>[], info: any): void
  (e: 'clearSearchTerm'): void
  (e: 'inputFocus', data: any): void
  (e: 'exposeAssoc', data: any): void
  (e: 'expose_search', data: any): void
  (e: 'slide_expose_search', data: any): void
  (e: 'clearCurSearchWord', data: any): void
}
const emit = defineEmits<SearchTermsEmits>()

const inputRef = ref<HTMLInputElement | null>(null)

const showInput = ref(false)
const isInputFocused = ref(false)

const searchButtonRef = ref<HTMLButtonElement | null>(null)

// 联想词原词拓展埋点信息
const originWordReportExtendData = ref<Object>({})

// const searchTermsArray = ref<SearchTerm[]>(props.searchTerms)

// 热词
const historyWords = ref<any>([])

const defaultWords = ref<any>([])
const hotWords = ref<any>([])
const hotImages = ref<any>([])
const showClearButton = ref(true)

const currentWord = ref<any>({
  keywords: '',
})

const status = ref({
  fetchStatus: 'idle',
  itemActiveIndex: -1,
  isSelectByKeyboard: false,
  lastEditType: '',
  saFirstView: true,
})

const searchData = ref<SearchTerm>({
  brand: null,
  crowdId: null,
  siteLang: null,
  terminal: null,
  weight: null,
  word: '',
  imgSrc: '',
  pageId: null,
  pageType: null,
  pageUrl: null,
  sort: null,
  type: '',
  from: '',
  mall_code_list: null,
  route_url: null,
  cateIds: null,
  categoryChannel: null,
  wordLabel: {
    icon: '',
    labelType: '',
    labelFront: '',
  },
})

// 联想词相关历史逻辑
const correctionStr = ref('')
const associationWords = ref({
  word: [],
})

const getKeywordsWithBff = apis.getKeywordsWithBff
const getAssociationWordsWithBff = apis.getAssociationWordsWithBff

const transfromAssociationBffData = (info: any) => {
  const { is_suggested, wordsInfo, catWordInfo } = info || {}
  if (is_suggested) {
    info.words = [...(wordsInfo || [])]
  } else {
    info.words = [...(catWordInfo || []), ...(wordsInfo || [])]
  }
}

const getGuideWordName = ({ keyword, searchCatName }: { keyword: any; searchCatName: any }) => {
  if (props.siteUID === 'hk' || props.siteUID === 'tw') {
    return props.language.SHEIN_KEY_PC_16957?.replace('{1}', keyword)?.replace('{0}', searchCatName)
  } else {
    return props.language.SHEIN_KEY_PC_16956?.replace('{0}', keyword)?.replace('{1}', searchCatName)
  }
}

const fetchAssociationWords = async (inputWord: string) => {
  const params = {
    word: inputWord.replace(/^\s+/g, '').replace(/\s+/g, ' '),
    ...props.fetchAssociationWordsParams,
  }

  if (!params.word) return
  const associationData = await getAssociationWordsWithBff(params)

  const { code, info } = associationData || {}
  originWordReportExtendData.value = {
    associationTraceId: info.trace_id,
    inputWordId: info.input_word_id,
    inputWord: params.word.trim(),
    type: 'originWordIdAndAssociateTraceId',
  }
  if (code != 0) return

  // mutation
  transfromAssociationBffData(info)

  let { words: word, is_suggested, guideWords } = info || {}
  let correction = ''

  const matchClass = 'match-text'

  const replacer = ({ text, wordLabel = {}}) => {
    // 纠错和普通联想词都展示筛选词
    const html = correction && typeof correction === 'string'
      ? text.replace(correction, `<span class="${matchClass}">${correction}</span>`)
      : text.replace(new RegExp(`(${inputWord.trim()})`, 'i'), `<span class="${matchClass}">$1</span>`)
    return `<div class="association-item-warp">
      <span class='association-item-text'>${html}</span>
      ${
        (function () {
          if (['trendword', 'trendstore'].includes(wordLabel?.labelType)) {
            return `<b class="association-hk-tag">${props.language.SHEIN_KEY_PC_30767}</b>`
          } else if (wordLabel?.labelType === 'choiceword') {
             return `<img class="association-choice-tag" src="${wordLabel?.icon}" height="14" alt="choiceword" />`
          }
          return ''
        })()
      }
    </div>`
  }

  const formater = (items) => {
    if (!Array.isArray(items)) return []
      return items.map((item) => {
        return {
          keywords: item.word,
          word_id: item.word_id || '-',
          trace_id: info.trace_id || '-',
          tag: item.wordLabel?.labelType,
          transformed: replacer({ text: item.word, wordLabel: item.wordLabel }),
          wordTagType: item?.wordTagType || 'common',
          imgUrl: item.img_url,
        }
      })
  }

  if (is_suggested) {
    if (props.isCorrectionRule) {
      word = null
    } else {
      correction = (Array.isArray(word) && word.shift()).word || ''
    }
  }

  correctionStr.value = correction

  const firstGuideWord = guideWords?.[0] || {}
    if (firstGuideWord.word) {
      word.unshift({
        tags: [],
        ...firstGuideWord,
      })
    }

  word = formater(word, 'word').slice(0,20 - correctionNum.value)

  if (currentWord.value.keywords && currentWord.value.keywords.trim()) {
    associationWords.value = { word }
  }
}

const fetchDefaultHotBff = async ({ goods_id }, defaultSearchWordPropsParams: FetchSearchTermsParams) => {
  status.value.fetchStatus = 'pending'
  try {
    const [ defaultRes, hotRes ] = await Promise.all([
      getKeywordsWithBff({ word_type: 1, goods_id, scene: goods_id ? 'detail' : 'home', ...defaultSearchWordPropsParams }),
      getKeywordsWithBff({ word_type: 2, scene: 'home' }),
    ])
    let keywords = (defaultRes.info?.keywords || []).map(item => ({ trace_id: defaultRes.info.trace_id, ...item }))
    let hotKeywords = (hotRes.info?.keywords || []).map(item => ({ trace_id: hotRes.info.trace_id, ...item }))

    emit('resolveSearchTerms', keywords, defaultRes.info)

    dealWithWords({ keywords, hotKeywords, goods_id })

    initSlideItems()

    status.value.fetchStatus = 'resolve'
  } catch (err) {
    status.value.fetchStatus = 'reject'

    console.log('搜索-底纹词、热词bff-err：', err)
  }
}

const dealWithWords = ({ keywords, hotKeywords, goods_id }) => {
  if (Array.isArray(keywords)) {
    defaultWords.value = goods_id ? keywords.slice(0, 1) : keywords
    searchData.value = defaultWords.value[0] || {}
  }
  hotKeywords = goods_id && Array.isArray(keywords) && keywords.length ? keywords || [] : hotKeywords
  if (Array.isArray(hotKeywords)){
    const results = hotKeywords.reduce((curr, next, index) => {
      next.active = index < 4
      next.imgSrc
        ? curr.hotImages.push(next)
        : curr.hotWords.push(next)
        // 没有图片时降级成普通搜索人工词
      return curr
    }, {
      hotImages: [],
      hotWords: [],
    })
    hotImages.value = results.hotImages
    hotWords.value = results.hotWords
  }
  // 非轮波的搜索框在这里曝光
  if(!defaultWords.value.length || showInput.value) {
    emit('expose_search', {
      currentWord: currentWord.value.guideObj
        ? currentWord.value.guideObj.keywords
        : currentWord.value.keywords,
      defaultWords: defaultWords.value,
    })
  }
}

let subscription: Subscription | null = null

const initSlideItems = () => {
  if (subscription) {
    subscription.unsubscribe()
  }

  const observable = createIntervalObservable(4000)

  subscription = observable.subscribe({
    next: (index: number) => {
      const _index = index % defaultWords.value.length
      const searchTerm = defaultWords.value[_index]
      if (searchTerm) {
        searchData.value = searchTerm

        emit('slide_expose_search', {
          searchData: searchData.value,
          currentWord: currentWord.value,
          searchTerm,
          index: _index,
          defaultWords: defaultWords.value,
        })
      }
    },
    error: (err: any) => console.error('Error:', err),
    complete: () => console.log('Completed'),
  })
}

const handleSearchBoxFocus = () => {
  showInput.value = true
  nextTick(() => {
    inputRef.value?.focus()
  })
}

const handleFocus = () => {
  if (isInputFocused.value) return

  isInputFocused.value = true
  status.value.isSelectByKeyboard = false

  emit('inputFocus', {
    activedMask: true,

    // all data for anaylsis
    status: status.value,
    correctionStr: correctionStr.value,
    associationNum: associationNum.value,
    searchData: searchData.value,
    currentWord: currentWord.value,
    associationWords: associationWords.value,
    historyWords: historyWords.value,
    hotWords: hotWords.value,
    hotImages: hotImages.value,
    defaultWords: defaultWords.value,
  })
}

const handleBlur = () => {
  if (currentWord.value.keywords === '') {
    showInput.value = false
  }
}

const handleKeyDown = ({ keyCode }: any) => {
  // 返回的关联词数组有长度
  if (!associationNum.value) return
  let activeIndex = status.value.itemActiveIndex
  // 键盘上下记录选中index, 添加标记
  if (keyCode == 38) {
    activeIndex--
    status.value.isSelectByKeyboard = true
  } else if (keyCode == 40) {
    activeIndex++
    status.value.isSelectByKeyboard = true
  } else {
    // 非回车清空标记
    if (keyCode != 13) {
      status.value.isSelectByKeyboard = false
      activeIndex = -1
    }
  }
  // 键盘选词标记 记录新词
  if (status.value.isSelectByKeyboard) {
    if (activeIndex > associationNum.value - 1) {
      activeIndex = 0
    }
    if (activeIndex <= -1) {
      activeIndex = associationNum.value - 1
    }
    if (activeIndex == 0 && correctionStr.value) {
      status.value.lastEditType = 'correction'
      currentWord.value = { keywords: correctionStr.value }
    } else {
      status.value.lastEditType = correctionNum.value ? 'correction' : 'popular'
      const { keywords } = associationWords.value.word[
        activeIndex - correctionNum.value
      ]
      currentWord.value = { keywords }
    }
  }

  status.value.itemActiveIndex = activeIndex
}

const handleClear = () => {
  currentWord.value = { keywords: '' }
  inputRef.value?.focus()

  emit('clearSearchTerm')
}

const debounceInput = debounce({
  func: async function (e) {
    const word = e.target.value
    await fetchAssociationWords(word)

    emit('exposeAssoc', {
      // all data for anaylsis
      status: status.value,
      correctionStr: correctionStr.value,
      associationNum: associationNum.value,
      searchData: searchData.value,
      currentWord: currentWord.value,
      associationWords: associationWords.value,
      historyWords: historyWords.value,
      hotWords: hotWords.value,
      hotImages: hotImages.value,
    })
  },
  wait: 300,
})

const initEventListeners = () => {
  // 点击搜索框外部隐藏联想词
  document.addEventListener('click', (e) => {
    if (e.target) {
      const target = e.target as HTMLElement
      const clickInside = target.closest('.search-box-regional')
      const clickIsSearchButton = target.closest('.search-button')

      // 编辑状态下不需要隐藏该操作区域
      if (props.isBigSearch && !showClearButton.value) return

      if (clickInside && !clickIsSearchButton) {

        isInputFocused.value = true
      } else {
        isInputFocused.value = false

        emit('inputFocus', {
          activedMask: false,
          currentWord: currentWord.value,
          associationWords: associationWords.value,
          historyWords: historyWords.value,
          hotWords: hotWords.value,
          hotImages: hotImages.value,
        })
      }
    }
  })
  // 监听搜索框输入
  inputRef.value?.addEventListener('input', debounceInput)
}

const disabledSearchButton = computed(() => {
  // if (status.value.fetchStatus === 'pending') return true

  return status.value.fetchStatus === 'resolve' && !currentWord.value.keywords && !searchData.value.word
})

const correctionNum = computed(() => {
  return correctionStr.value ? 1 : 0
})

const popularNum = computed(() => {
  return associationWords.value.word.length
})

const associationNum = computed(() => {
  return correctionNum.value + popularNum.value
})

const historyHotNum = computed(() => {
  return historyWords.value.length + hotWords.value.length + hotImages.value.length
})

const goSearchPage = async ({
  keyword,
  result_type = 2,
  position = 1,
  tag = '',
  guideObj: { search_id, search_cat, full_name } = {} as any,
  // event,
  clickSearchBtn,
  wordTagType = 'common',
  wordOriginRecord = {},
  wordExtraReport,
  reportExtendData,
}) => {
    tag = tag || keyword?.wordLabel?.labelType || ''
    let ana_keyword = keyword?.word || keyword
    const encodeKeyword = encodeURIComponent(ana_keyword)

    let srcIdentifier = getQueryString({ key: 'src_identifier' }) || ''
    let keyArr = ['on', 'cn', 'hz', 'ps', 'jc']
    if (srcIdentifier) {
      let idx = 0
      srcIdentifier.split('`').forEach(item => {
        let key = item.split('=')[0]
        if (keyArr.includes(key)) {
          idx++
        }
      })
      if (idx > 1) {
        // session存一个src_identifier_pre_search，下游服务需要，因为后面去搜索页src_identifier这个字段会被修改
        sessionStorage.setItem('src_identifier_pre_search', srcIdentifier)
      }
    } else {
      // url上没有src_identifier的时候，src_identifier_pre_search里面对应的字段传空
      sessionStorage.setItem('src_identifier_pre_search', '')
    }

    let url, params
    if (keyword?.pageUrl) {
      url = keyword.pageUrl
      params = { search_redir: 1, search_words: encodeKeyword }
      if (keyword?.pageType == 2 || keyword?.pageType == 5) {
        // mallCode
        const mallCode = keyword?.pageId?.split('/')[1] || ''
        if (mallCode) {
          params.mallCode = mallCode
        }
      }
    } else {
      url = `/pdsearch/${encodeKeyword}/`
      params = search_id
        ? {
          search_type: 'all',
          search_id: search_id,
          search_cat: encodeURIComponent(search_cat || ''),
        }
        : {
          search_type: 'all',
        }
    }
    // S-analysis
    const originWord = result_type == 8 ? currentWord.value.keywords : ana_keyword // input 原词
    const trackData = { encodeKeyword, keyword, full_name, result_type, search_redir: params.search_redir, position, originWord, tag, wordTagType, wordOriginRecord, reportExtendData, wordExtraReport }
    const urlTrackData = props.getTrackData(trackData) || {}
    const optionParams: any = {
      src_module: 'search',
      src_identifier: encodeURIComponent(`st=${result_type}\`sc=${originWord}\`sr=${result_type == 8 ? ana_keyword : 0}\`ps=${position}`),
      src_tab_page_id: (window as any).getSaPageInfo?.tab_page_id || '',
      // 新增搜索来源 search_source
      search_source: getSearchSourceBySearchType(result_type),
      src_identifier_pre_search: encodeURIComponent(sessionStorage.getItem('src_identifier_pre_search') as any),
    }
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)([0-9A-Za-z]{5}|[0-9A-Za-z]{7})$/
    if (reg.test(keyword)) {
      // 根据营销code获取专题标识符或跳转链接
      const { hrefTarget, hrefType } = await apis.getMarketingCodeWithBff(keyword)
      if (hrefTarget && hrefType == 'webLink') {
        url = hrefTarget
        optionParams.search_redir = 1
        optionParams.search_words = keyword
      }
    }
    if (reg.test(keyword)) {
      // 符合匹配营销码主搜和上位词就加上marketCode标识：
      optionParams.market_code = '1'
    }
    // E
    const isHotWordSearch = [6, 9].includes(result_type)
    const historyWordSearch = result_type === 5
    const associativeWordSearch = [4, 7].includes(result_type)
    if (isHotWordSearch) {
      params.source = 'hotWord'
    } else if (historyWordSearch) {
      params.source = 'historyWord'
    } else if (associativeWordSearch) {
      params.source = 'association'
    } else if (clickSearchBtn) {
      params.source = 'search'
    }

    url = `${url}${
      url.includes('?') ? '&' : '?'
    }${decodeURIComponent(stringifyQueryString({ queryObj: { ...params, ...urlTrackData, ...optionParams } }) || '')}`

    location.href = url.trim().replace('@', '-')
}

const handleItemActiveIndex = (index: number) => {
  status.value.itemActiveIndex = index
}

const handleSearch = () => {
  let keyword, result_type, position

  if (currentWord.value.keywords) {
    keyword = currentWord.value.keywords
    keyword = keyword.charCodeAt(keyword.length - 1) === 8288
      ? keyword.slice(0, keyword.length - 1)
      : keyword
    // 限制搜索词长度不超过150词 wiki=1460912287
    if (keyword.length > 150) {
      keyword = keyword.slice(0, 150)
    }
    position = 1
  } else {
    keyword = searchData.value
    result_type = 3
    position = defaultWords.value.findIndex(_ => _.word === searchData.value.word) + 1
  }

  if (status.value.lastEditType == 'correction') result_type = 8
  else if (status.value.lastEditType == 'popular') result_type = 4

  if (currentWord.value.guideObj) {
    keyword = currentWord.value.guideObj.keywords
    result_type = 7
  }

  if (keyword) {
    if (keyword.word || keyword.trim?.()) {
      goSearchPage({
        keyword: isObject(keyword)
          ? keyword
          : keyword.trim().replace(/\s+/g, ' '),
        result_type,
        position,
        guideObj: currentWord.value.guideObj,
        clickSearchBtn: true,
        reportExtendData: originWordReportExtendData.value,
      } as any)
    } else {
      // clearWord()
    }
  } else {
    location.href = '/'
  }

  return false
}

const initSearchDataFromUrl = () => {
  const search_type = getQueryString({ key: 'search_type' })
  let keywords = ''
  if (search_type !== 'store' && typeof (window as any).gbRawData !== 'undefined') {
    keywords = htmlDecode({
      text: (window as any)?.gbRawData?.results?.cat_info?.keywords || (window as any)?.gbRawData?.cat_info?.keywords,
    })
  }

  if (keywords) {
    const search_id = getQueryString({ key: 'search_id' })
    const search_cat = getQueryString({ key: 'search_cat' })

    if (search_id) {
      currentWord.value = {
        keywords: keywords,
        guideObj: {
          keywords,
          search_id,
          search_cat,
          full_name: getGuideWordName({
            keyword: keywords,
            searchCatName: search_cat,
          }),
        },
      }
    } else {
      currentWord.value = { keywords }
    }
  }
}

const handleCleanHistoryWord = () => {
  // 长搜索框时的逻辑
  if (props.isBigSearch) {
    showClearButton.value = false
    return
  }

  // 原始逻辑
  if (historyWords.value.length) {
    historyWords.value = []
    const lang = props.lang
    localStorage.removeItem(`${lang}SearchHisotryWords`)
  }
}

const handleCloseClearBtn = () => {
  if (props.isBigSearch) {
    showClearButton.value = true
  }
}

const handleClearCurSearchWord = (index: number) => {
  const lang = props.lang

  let searchHistoryWords = JSON.parse(
    localStorage.getItem(`${lang}SearchHisotryWords`) || '[]',
  )
  searchHistoryWords.splice(index, 1)

  initLocalHistoryWords(searchHistoryWords)

  if (!historyWords.value.length) {
    handleCloseClearBtn()
  }

  localStorage.setItem(
    `${lang}SearchHisotryWords`,
    JSON.stringify(searchHistoryWords),
  )

  emit(('clearCurSearchWord'), index)
}

const handleClickHistoryWords = ({ item, index }) => {
  if (props.isBigSearch && !showClearButton.value) {
    handleClearCurSearchWord(index)
    return
  }

  goSearchPage({
    keyword: item.keywords,
    result_type: 5,
    position: index + 1,
    guideObj: item.guideObj,
    wordTagType: item?.wordTagType,
  } as any)
}

const fetchHotWords = (defaultSearchWordPropsParams: FetchSearchTermsParams = {}) => {
  const goods_id = (location.pathname?.match(/.+-p-((\d+))(-cat-(\d+))?\.html/) || [])[1]
  fetchDefaultHotBff({ goods_id }, defaultSearchWordPropsParams)
}

watchEffect(() => {
  if (typeof window === 'undefined') {
    // SSR 环境中不执行
    return
  }
  const goods_id = (location.pathname?.match(/.+-p-((\d+))(-cat-(\d+))?\.html/) || [])[1]
  fetchDefaultHotBff({ goods_id: goods_id }, props.fetchSearchTermsParams || {})
})

const initLocalHistoryWords = (localHistoryWords: any) => {
  if (Array.isArray(localHistoryWords) && localHistoryWords.length) {
    const newHistoryWords = localHistoryWords.map((item) => {
      if (isObject(item)) {
        (item as any).guideObj.full_name = getGuideWordName({
          keyword: (item as any).keywords,
          searchCatName: (item as any).guideObj.search_cat,
        })
        return item
      } else {
        return { keywords: item }
      }
    })

    historyWords.value = newHistoryWords
  } else {
    historyWords.value = []
  }
}

const initHistoryHotWord = (lang: string) => {
  let localHistoryWords = []
  try {
    localHistoryWords = JSON.parse(
      localStorage.getItem(`${lang}SearchHisotryWords`) || '[]',
    )
  } catch (error) {
    console.log(error)
  }

  initLocalHistoryWords(localHistoryWords)
}

onMounted(() => {
  const lang = props.lang
  // let defaultSearchWordPropsParams = props.fetchSearchTermsParams || {}

  initEventListeners()

  initHistory(lang)
  initHistoryHotWord(lang)

  initSearchDataFromUrl()

  // fetchHotWords(defaultSearchWordPropsParams)
  // 兼容外部自定义传参的情况下首次请求不需要走fetchHotWords而是通过watchEffect触发
  // if(!Object.keys(props.fetchSearchTermsParams || {})?.length) {
  //   fetchHotWords()
  // }
})

watch(currentWord, (newVal) => {
  if (newVal && newVal.keywords && newVal.keywords.trim()) {
    showInput.value = true
    if (status.value.isSelectByKeyboard) return
    status.value.lastEditType = ''
    if (newVal.guideObj && newVal.keywords !== newVal.guideObj.keywords) {
      delete newVal.guideObj
    }
  } else {
    // 输入框空值
    status.value.isSelectByKeyboard = false
    status.value.itemActiveIndex = -1
    associationWords.value = { guideWords: [], word: [] }
    correctionStr.value = ''
    delete newVal.guideObj
  }
}, {
  deep: true,
})

const vm = getCurrentInstance()

defineExpose({
  fetchHotWords,
  getProxy: () => vm?.proxy,
})

</script>

<style lang="less" scoped>
.bsc-search-box {
  --bsc-inner-search-button-width: var(--bsc-search-box-width, 40px);
  --bsc-inner-search-button-height: var(--bsc-search-box-height, 38px);

  position: relative;
  max-width: var(--bsc-search-box-max-width, 530px);
  width: auto;
  height: var(--bsc-inner-search-button-height);

  padding-right: var(--bsc-inner-search-button-width);

  .search-box {
    display: flex;
    align-items: center;
    font-weight: 400;
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: 10px;

    border: 1px solid var(--bsc-search-box-outline-color, #222);
    border-right: none;
    font-size: var(--bsc-search-box-font-size, 12px);
    box-sizing: border-box;
    outline: 0;
  }

  .search-trend-icon {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }

  .search-tag-icon {
    min-width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: center;
    transform: scale(1.25);
    background-image: url('https://img.shein.com/images3/2023/10/27/f5/1698394226527e3792513f865e0b2d6124c9e7a646.png');
  }

  .search-label-front-wrapper {
    margin-left: 4px;
    font-size: 12px;
    color: #999;
  }
  .search-label-front {
    font-style: normal;
  }

  .search-term {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--bsc-search-term-color, #222);
  }
  .search-term .empty {
    color: var(--bsc-search-term-empty-color, #767676);
  }

  .search-term .hk-tag {
    font-size: 12px;
    color: #9462FF;
  }

  .icon-choice {
    margin-left: 4px;
    margin-top: -1px;
  }

  .search-input {
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: calc(var(--bsc-inner-search-button-width) - 10px);
    box-sizing: border-box;
    border: 1px solid var(--bsc-search-box-outline-color, #222);
    border-right: none;
    font-size: var(--bsc-search-input-font-size, 12px);
    outline: 0;
  }
  .search-input .search-term {
    color: var(--bsc-search-input-color, #767676);
  }

  .clear-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: var(--bsc-clear-button-right, 50px);
    display: grid;
    place-content: center;
    width: 16px;
    font-size: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    height: 100%;
    min-width: 40px;
    width: var(--bsc-inner-search-button-width);
    right: 0px;
    top: 0;
    border: 1px solid var(--bsc-search-input-border-color, #222);
    color: var(--bsc-search-button-color, #fff);
    background-color: var(--bsc-search-button-bg-color, #222);
    cursor: pointer;
  }
  .search-button.disabled {
    --bsc-search-icon-color: rgba(0, 0, 0, .6);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
  }

  .search-content {
    position: absolute;
    top: var(--bsc-inner-search-button-height);
    min-width: 250px;
    max-width: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 500;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.bsc-search-box.search-box-round {
  padding-right: 0;

  .search-box {
    border-radius: 2000px;
    border-right-color: rgb(34, 34, 34);
    border-right-style: solid;
    border-right-width: 1px;
    background-color: white;
  }

  .search-input {
    border-right-color: rgb(34, 34, 34);
    border-right-style: solid;
    border-right-width: 1px;
    border-radius: 2000px;
  }

  .search-button {
    right: 2px;
    width: 52px;
    border-radius: 2000px;
    height: 34px;
    top: 2px;
  }

  .search-content {
    top: 20px;
    padding-top: 18px;
    z-index: -1;

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .search-button.disabled {
    --bsc-search-icon-color: white;
    border: none;
    background-color: #959595;
    color: rgba(0, 0, 0, 0.6);
  }
  .clear-button {
    right: var(--bsc-clear-button-right, 65px);
  }
}

.sui-animation__selectmenu-enter,
.sui-animation__selectmenu-enter-active {
  -webkit-animation: sui-selectmenu-in 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  animation: sui-selectmenu-in 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.sui-animation__selectmenu-leave,
.sui-animation__selectmenu-leave-active {
  -webkit-animation: sui-selectmenu-out 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  animation: sui-selectmenu-out 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
@-webkit-keyframes sui-selectmenu-in {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sui-selectmenu-in {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes sui-selectmenu-out {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
@keyframes sui-selectmenu-out {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.sh_pc_sui_icon_search_24px_shein {
  vertical-align: middle;
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4zNDMxNSA1LjM0MzE1QzguNDY3MzQgMi4yMTg5NSAxMy41MzI3IDIuMjE4OTUgMTYuNjU2OSA1LjM0MzE1QzE5LjU0MTYgOC4yMjc4NyAxOS43NjI3IDEyLjc2NzYgMTcuMzIwMiAxNS45MDZMMjEuNjA2NiAyMC4xOTI0TDIwLjE5MjQgMjEuNjA2NkwxNS45MDYgMTcuMzIwMkMxMi43Njc2IDE5Ljc2MjcgOC4yMjc4NyAxOS41NDE2IDUuMzQzMTUgMTYuNjU2OUMyLjIxODk1IDEzLjUzMjcgMi4yMTg5NSA4LjQ2NzM0IDUuMzQzMTUgNS4zNDMxNVpNMTUuMjQyNiA2Ljc1NzM2QzEyLjg5OTUgNC40MTQyMSA5LjEwMDUgNC40MTQyMSA2Ljc1NzM2IDYuNzU3MzZDNC40MTQyMSA5LjEwMDUgNC40MTQyMSAxMi44OTk1IDYuNzU3MzYgMTUuMjQyNkM5LjEwMDUgMTcuNTg1OCAxMi44OTk1IDE3LjU4NTggMTUuMjQyNiAxNS4yNDI2QzE3LjU4NTggMTIuODk5NSAxNy41ODU4IDkuMTAwNSAxNS4yNDI2IDYuNzU3MzZaIiBmaWxsPSIjRkZGRkZGIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
}
</style>
