import type { AS_PersonalCenter } from '../../../types'

const standard: AS_PersonalCenter.AnalysisFuncs = {
  'expose_remember_account_pop': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_remember_account_pop_no': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_remember_account_pop_yes': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_switch_account': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_switch_account': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_coupon_reminder': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_coupon_reminder': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_point_reminder': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_point_reminder': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_news': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_topsite_recently_viewed': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_top_site_club': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_saver': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_saver': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_top_site_account': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_topsite_self_image': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_topsite_my_profile': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_order_all': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_topsite_my_coupons': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_topsite_my_points': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_topsite_more_service': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_top_site_vip': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_topsite_my_coupons': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_topsite_my_points': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_order_all': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
