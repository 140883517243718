import type { DS_PersonalCenter } from '../../../types'

// 获取未读消息
export const fetchUnreadMsg: DS_PersonalCenter.APIS_THIS['fetchUnreadMsg'] = async function () {
  const result = await this.$schttp<Object>({
    url: '/user/v3/msg_unread',
  })
  return result.data
}

// 获取用户消息
export const fetchUserInfo: DS_PersonalCenter.APIS_THIS['fetchUserInfo'] = async function () {
  const result = await this.$schttp<Object>({
    url: '/user/center/first_part_info',
  })
  return result.data
}

// 获取多语言
export const fetchBffLanguage: DS_PersonalCenter.APIS_THIS['fetchBffLanguage'] = async function () {
  const result = await this.$schttp<Object>({
    url: '/system/configs/page_multi_language_mapping',
    method: 'POST',
    data: {
      pages: ['userDropdown'],
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return result.data
}
