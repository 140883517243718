<template>
  <div
    class="bsc-banner-countdown"
  >
    <StaticImage
      :context="context"
      :prop-data="propData"
      :index="index"
      class="longBannerHeight-full"
    />
    <div
      v-if="isOpenCountdown && countTimer.timeId"
      class="bsc-banner-countdown__absulote"
      :style="{ minWidth: countMinWidth + 'px' }"
    >
      <div class="bsc-banner-countdown__relative">
        <ResizeFont
          ref="countdownResizeFont"
          class="bsc-banner-countdown__countdown"
          :style="countdownStyle"
        >
          <template v-if="countdownType == 3 && Number(timeObj.D)">
            <ResizeFontText
              :style="itemStyle"
              :init-font-size="24"
              :resize-font-min="5"
              :resize-font-step="1"
              :content="timeObj.D"
            />
            <ResizeFontText
              :style="signStyle"
              :init-font-size="24"
              :resize-font-min="5"
              :resize-font-step="1"
              :content="'D'"
            />
          </template>
          <template
            v-for="index in 6"
            :key="index"
          >
            <ResizeFontText
              :style="index % 2 ? itemStyle : signStyle "
              :init-font-size="24"
              :resize-font-min="5"
              :resize-font-step="1"
              :content="getHMSContent(index)"
            />
          </template>
        </ResizeFont>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { debounce } from '@shein/common-function'
import { commonProps } from '../../common/common-props.js'
import StaticImage from '../StaticImage/Index.vue'
import ResizeFont from '../..//base/ResizeFont/ResizeFont.vue'
import ResizeFontText from '../../base/ResizeFont/ResizeFontText.vue'

import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const appConfigs = useAppConfigs()

const GB_cssRight = appConfigs?.$envs?.cssRight || false

const HMSContent = {
  2: 'H',
  4: 'M',
  6: 'S',
}

const props = defineProps({
  ...commonProps,
  countTimer: {
    type: Object,
    default: () => ({}),
  },
})

const countdownResizeFont = ref(null)

const { props: propDataProps = {} } = props.propData || {}
const { metaData = {} } = propDataProps || {}
const { isOpenCountdown = true, countdownType = 1 } = metaData || {}

const countMinWidth = ref(1350)
const countWidth = ref(280)
const widthWithoutScrollbar = ref(2560)

const timeObj = computed(() => props.countTimer?.timeObj || {})

const itemStyle = computed(() => {
  const { numColor = '#222' } = metaData || {}
  return {
    textAlign: 'center',
    color: numColor,
  }
})

const signStyle = computed(() => {
  const { hmsColor = '#fff' } = metaData || {}
  return {
    color: hmsColor,
  }
})

const countdownStyle = computed(() => {
  let style = {
    width: countWidth.value + 'px',
  }
  if (GB_cssRight) {
    style.right = 'unset'
    style.left = 0
  } else {
    style.right = 0
  }
  return style
})

const getHMSContent = (index) => {
 switch (index) {
    case 1:
      return timeObj.value.H
    case 3:
      return timeObj.value.M
    case 5:
      return timeObj.value.S
    default:
      break
  }
  return HMSContent[index]
}

const resizeHandle = (isCreated = false) => {
  if(typeof window == 'undefined') return
  const currentWidthWithoutScrollbar = document?.documentElement?.clientWidth
  if (currentWidthWithoutScrollbar < 1446) {
    countMinWidth.value = currentWidthWithoutScrollbar - 48 - 48
    countWidth.value = countMinWidth.value / 1350 * 280
  } else {
    countMinWidth.value = 1350
    countWidth.value = 280
  }
  if (!isCreated) {
    const isEnlarge = widthWithoutScrollbar.value < currentWidthWithoutScrollbar
    countdownResizeFont.value?.resetSizeDone()
    nextTick(() => {
      if (isEnlarge) {
        // 浏览器放大
        countdownResizeFont.value?.handleEnlargeFontSize(countdownResizeFont.value?.$el)
      } else {
        countdownResizeFont.value?.handleResizeFontSize(countdownResizeFont.value?.$el)
      }
    })
  }
  widthWithoutScrollbar.value = currentWidthWithoutScrollbar
}

onMounted(() => {
  resizeHandle(true)
  window.addEventListener(
    'resize',
    debounce({
      func: () => {
        resizeHandle()
      },
      wait: 200,
    }),
  )
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandle)
})
</script>

<style lang="less" scoped>
.bsc-banner-countdown {
  position: relative;
  margin: 0 auto;
  .longBannerHeight-full {
    /deep/ .bsc-static-image {
      height: 100%;
    }
  }
  &__absulote {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    pointer-events: none; // 允许事件穿透到底层的图片
  }
  &__relative {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__countdown {
    position: absolute;
    top: 51%;
    right: 0;
    display: flex;
    justify-content: flex-end /* rtl: flex-start */;
    font-weight: 700;
    transform: translate(0, -50%);
    text-align: right;
    margin: 0;
    // 防止截断倒计时动效
    overflow: unset;
    text-overflow: unset;
    white-space: nowrap;
    direction: ltr /* rtl: ltr */;
    span {
      margin: 0 7px;
      vertical-align: middle;
    }
  }
  @media (max-width: 1446px) {
    &__countdown span {
      margin: 0 6px;
    }
  }
  @media (max-width: 1280px) {
    &__countdown span {
      margin: 0 5px;
    }
  }
  @media (max-width: 1024px) {
    &__countdown span {
      margin: 0 4px;
    }
  }
  @media (max-width: 960px) {
    &__countdown span {
      margin: 0 3px;
    }
  }
  @media (max-width: 780px) {
    &__countdown span {
      margin: 0 2px;
    }
  }
  .bsc-static-image {
    height: 100%;
  }
}
</style>
