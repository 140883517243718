import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { debounce } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { getLangMap } from '@shein-aidc/bs-cart-item-laptop'
import { getSource } from '../../../common/dataSource/index'
import type { C_MiniCart } from '../../../types/index'
import { LANGUAGE_KEYS, NEW_POS_KEYS } from '../constants'
import useWindowSize from './useWindowSize'
import { getMaxZIndex } from '../utils/index'
// import mock from '../mock'

// eslint-disable-next-line max-lines-per-function
export default function useMiniCart(options: C_MiniCart.IProps, emit) {
  const appConfigs = useAppConfigs()
  const cssRight = appConfigs.$envs.cssRight
  const apis = getSource({ appConfigs, dataSource: options.dataSource })

  const abtInfo = ref<any>({})
  const getAbtInfo = async () => {
    const res = await apis.fetchAbt({ posKeys: NEW_POS_KEYS.join(',') })
    if (res.code == '0') {
      abtInfo.value = res.info?.pos || {}
    }
  }
  const autoUseCoupon = computed(() => abtInfo.value?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? '1' : '0')
  const showNewUserTag = computed(() => abtInfo.value?.orderCouponNewUser?.param?.orderCoupon_newUser === 'new')

  const show = ref(false)
  const renderSize = ref(0) // 渲染的商品数量
  const toggle = (value = !show.value) => {
    show.value = value
    if(value) {
      if(renderSize.value) {
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            renderSize.value = Math.max(carts.value?.length || 0, 120)
          })
        })
      } else {
        renderSize.value = Math.max(carts.value?.length || 0, 120)
      }
    }
  }
  const hideMiniCart = () => {
    const timer = setTimeout(() => {
      !options.test && toggle(false)
      clearTimeout(timer)
    }, options.hideAfter)

  }

  const maxHeight = ref('auto')
  const updateMaxHeight = () => {
    const top = document.querySelector('.j-bsc-mini-cart__container')?.getBoundingClientRect()?.top || 0
    maxHeight.value = `${Math.floor(document.body.clientHeight - top)}px`
  }
  const { height } = useWindowSize()
  watch(height, debounce({
    func: updateMaxHeight,
    wait: 100,
  }))
  const overlayOffset = ref({
    top: '0',
    left: '0',
  })
  const updateOverlayOffset = () => {
    const { top = 0, left = 0, right = 0 } = document.querySelector('.j-bsc-mini-cart__content')?.getBoundingClientRect() || {}
    const diffRight = document.body.clientWidth - right
    overlayOffset.value = {
      top: `${-top}px`,
      left: `${-(cssRight ? diffRight : left)}px`,
    }
  }
  const zIndex = ref(0)
  const updateZIndex = () => {
    const maxZIndex = getMaxZIndex()
    zIndex.value = maxZIndex + 1
  }
  const placeholderStyles = ref({
    width: '0',
    height: '0',
    top: '0',
    left: '0',
  })
  const updatePlaceholderStyles = () => {
    const triggerRect = document.querySelector('.j-bsc-mini-cart__trigger')?.getBoundingClientRect()
    const containerRect = document.querySelector('.j-bsc-mini-cart__container')?.getBoundingClientRect()
    if (!triggerRect || !containerRect) return
    const diffY = containerRect.top - triggerRect.bottom
    const diffLeft = triggerRect.left - containerRect.left - 5
    const diffRight = containerRect.right - triggerRect.right + 5
    placeholderStyles.value = {
      width: `${triggerRect.width + 10}px`,
      height: `${diffY}px`,
      top: `${-diffY}px`,
      left: `${cssRight ? diffRight : diffLeft}px`,
    }
  }

  const getApiParams = async (params = {}) => {
    const addressInfo = options.getAddressInfo()
    return {
      address_id: addressInfo.addressId,
      auto_use_coupon: autoUseCoupon.value, // 是否自动使用优惠券
      city: addressInfo.city || '',
      country_id: addressInfo.countryId,
      couponFilterGoods: [],
      district: addressInfo.district || '',
      filterTagId: '',
      filter_tag: '',
      holdTopFilterTagIds: '',
      isOpenScreenEfficiency: '1',
      postcode: addressInfo.postcode || '',
      state: addressInfo.state || '',
      userLocalSizeCountry: options.getUserLocalSizeCountry(),
      callScene: 'miniCart',
      ...params,
    }
  }

  const loading = ref(false)
  const mallCartInfo = ref<any>({})
  const cartInfo = ref<any>({})
  const carts = ref<any>([])
  const mallShippingInfo = ref({})
  const mallCarts = ref<any>([])

  interface ILevel {
    append_id_list: string[],
    cart_id_list: string[],
  }
  const handleCartInfo = () => {
    let cartList = []
    const _mallShippingInfo = {}
    mallCarts.value = mallCartInfo.value.mallCarts || []
    mallCarts.value.forEach(mall => {
      const mall_level: ILevel | null = mall.mall_code ? {
        append_id_list: [],
        cart_id_list: [],
      } : null
      mall.shops?.forEach(shop => {
        const shop_level: ILevel | null = shop.store_code && shop.shopName ? {
          append_id_list: [],
          cart_id_list: [],
        } : null
        shop.contentData?.forEach(content => {
          const content_level: ILevel | null = (content.groupHeadInfo?.promotionTips?.html_text || content.groupHeadInfo?.promotionTips?.text) ? {
            append_id_list: [],
            cart_id_list: [],
          } : null
          content.productLineInfoList?.forEach(product => {
            delete product.product.product_promotion_info
            if(product.isInvalid == '1') { // 已售罄
              return
            }
            product.isDisabled = product.aggregateProductBusiness.showNewUsersBonus == '1' || product.aggregateProductBusiness.isPresent == '1' || product.aggregateProductBusiness.isAddBuy == '1' || product.isPayMemberGift == '1'
            if(mall_level) {
              mall_level.append_id_list.concat(product.appendIds || [])
              mall_level.cart_id_list.push(product.id)
            }
            if(shop_level) {
              shop_level.append_id_list.concat(product.appendIds || [])
              shop_level.cart_id_list.push(product.id)
            }
            if(content_level) {
              content_level.append_id_list.concat(product.appendIds || [])
              content_level.cart_id_list.push(product.id)
            }
          })
          cartList = cartList.concat(content.productLineInfoList || [])
          if(content_level) {
            content.groupHeadInfo.data.append_id_list = [...new Set(content_level.append_id_list)]
            content.groupHeadInfo.data.cart_id_list = content_level.cart_id_list
          }
        })
        if(shop_level) {
          shop.append_id_list = [...new Set(shop_level.append_id_list)]
          shop.cart_id_list = shop_level.cart_id_list
        }
      })
      if(mall_level) {
        mall.append_id_list = [...new Set(mall_level.append_id_list)]
        mall.cart_id_list = mall_level.cart_id_list
      }
      if(mall.type == 0) {
        _mallShippingInfo['platform'] = mall.shippingActivityData || {}
      } else if(mall.type == 1 || mall.type == -1) {
        mall.mall_code && (_mallShippingInfo[`'${mall.mall_code}'`] = mall.shippingActivityData || {})
      }
    })
    carts.value = cartList
    mallShippingInfo.value = _mallShippingInfo
  }
  const handleCartRes = (res, config?: any) => {
    if (res.code == '0') {
      if (!config?.__isExternal__) {
        cartTagTip.update({
          noTips: true,
          needUpdateCartNum: true,
          cartInfo: {
            cartSumQuantity: res.info.cartSumQuantity,
          },
        })
      }
      emit('update', { config, cartRes: {
        cartSumQuantity: res.info.cartSumQuantity,
        mallCartInfo: res.info.mallCartInfo || {},
      }})
      mallCartInfo.value = res.info.mallCartInfo || {}

      delete res.info.mallCartInfo
      delete res.info.bnplCheckoutInfo
      delete res.info.businessModelForCheckout
      delete res.info.defaultAddress
      delete res.info.maxRecommendNum
      delete res.info.originPriceSelf
      delete res.info.paypalCheckoutInfo
      delete res.info.recommend
      delete res.info.totalPriceSelf
      delete res.info.effectiveProductLineSumQuantity
      delete res.info.over_member_limit_desc

      cartInfo.value = {...(res.info || {}), isExternal: res.isExternal || false}

      handleCartInfo()
    }
  }
  /**
   * 获取购物车数据
   */
  const getCartInfo = async (config?: any) => {
    try {
      if (loading.value) return
      loading.value = true
      const data = await getApiParams()
      const res = await apis.fetchCartInfo(data)
      // const res = mock
      handleCartRes(res, config)
      loading.value = false
      return res
    } catch(error) {
      loading.value = false
      return error
    }
  }

  const modifyCartCheckStatus = async (params) => {
    try {
      const data = await getApiParams(params)
      const res = await apis.fetchModifyCartCheckStatus(data)
      handleCartRes(res)
      return res
    } catch(error) {
      return error
    }
  }

  const cartUpdate = async (params) => {
    try {
      const data = await getApiParams(params)
      const res = await apis.fetchCartUpdate(data)
      handleCartRes(res)
      return res
    } catch(error) {
      return error
    }
  }

  const cartDelete = async (params) => {
    try {
      const data = await getApiParams(params)
      const res = await apis.fetchCartDelete(data)
      handleCartRes(res)
      return res
    } catch(error) {
      return error
    }
  }

  const isPlatform = computed(() => {
    return cartInfo.value?.isMultiMall == '1' ? true : false
  })
  const cartItemMap = computed(() => {
    return carts.value?.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {}) || {}
  })

  // 有效商品组列表
  const validMallCarts = computed(() => {
    return mallCarts.value?.filter((mallCart) => mallCart.type == 1) || []
  })

  const soldoutItems = computed(() => {
    return carts.value.filter(item => item.isInvalid == '1')
  })

  const soldoutIds = computed(() => {
    return soldoutItems.value.map(item => item.id)
  })

  /**
   * 价格模式
   * normal: 普通价格模式
   * suggestV1: 法国合规价模式
   * suggestV1Special: 波兰合规价模式
   * suggestV2: 德国合规价模式
   */
  const priceMode = computed(() => {
    return mallCartInfo.value?.priceMode || 'normal'
  })

  const language = ref({})
  const getLanguage = async () => {
    try {
      const langsMap = {
        ...getLangMap(),
        ...LANGUAGE_KEYS.reduce((acc, cur) => {
          acc[cur] = cur
          return acc
        }, {}) as any,
      }
      const res = await apis.fetchLanguage(langsMap)
      language.value = res
    } catch(error) {
      console.log(error)
    }
  }

  watch(() => cartInfo.value, () => {
    updateMaxHeight()
  }, { deep: true })

  const handleMouseleave = debounce({
    func: hideMiniCart,
    wait: 100,
  })
  const handlePageVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      hideMiniCart()
    }
  }
  const init = async () => {
    document.addEventListener('mouseleave', handleMouseleave)
    document.addEventListener('visibilitychange', handlePageVisibilityChange)
    updateZIndex()
    await getAbtInfo()
    await Promise.all([getCartInfo(), getLanguage()])
    window.requestIdleCallback(() => {
      if(!renderSize.value) {
        renderSize.value = 6
      }
    })
  }
  onMounted(() => {
    init()
  })
  onUnmounted(() => {
    document.removeEventListener('mouseleave', handleMouseleave)
    document.removeEventListener('visibilitychange', handlePageVisibilityChange)
  })

  return {
    show,
    loading,
    language,
    mallCartInfo,
    cartInfo,
    carts,
    mallShippingInfo,
    mallCarts,
    isPlatform,
    maxHeight,
    cartItemMap,
    soldoutItems,
    soldoutIds,
    priceMode,
    validMallCarts,
    overlayOffset,
    showNewUserTag,
    zIndex,
    placeholderStyles,
    toggle,
    getCartInfo,
    modifyCartCheckStatus,
    cartUpdate,
    cartDelete,
    updateMaxHeight,
    updateOverlayOffset,
    hideMiniCart,
    updatePlaceholderStyles,
    renderSize,
  }
}
