<template>
  <div class="bsc-mini-cart-mall-header">
    <div class="bsc-mini-cart-mall-header__content">
      <slot></slot>
      <div class="bsc-mini-cart-mall-header__title">
        <span
          :title="mallName"
          class="bsc-mini-cart-mall-header__title-text"
        >
          {{ mallName }}
        </span>
        <span class="bsc-mini-cart-mall-header__title-tag">
          <SLabel
            v-if="mallDesc"
            type="success"
            style="font-weight: normal; font-size:10px; font-family: 'SF UI Display';min-width: 20px;
    overflow: hidden;text-overflow: ellipsis;max-width: 100%"
          >
            {{ mallDesc }}
          </SLabel>
        </span>
      </div>
    </div>
    <div
      v-if="shippingTip"
      v-expose="analysisDataEvent('expose_shipping_activity.comp_mini-cart')"
      class="bsc-mini-cart-full-promotion-item bsc-mini-cart-mall-header__shipping"
    >
      <span
        class="bsc-mini-cart-mall-header__shipping-tip"
        v-html="shippingTip"
      ></span>
      <MiniCartCountDown
        v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
        :time-stamp="firstFreeShipping.countDownTime"
        :is-left-time="true"
        :language="language"
      />
    </div>
    <MallFullPromotions
      :language="language"
      :promotion-data="promotionData"
      :show-new-user-tag="showNewUserTag"
    />
  </div>
</template>

<script name="BMiniCartMallHeader" setup lang="ts">
import { computed, defineProps, inject } from 'vue'
import { SLabel } from '@shein-aidc/sui-label/laptop'
import MiniCartCountDown from '../mini-cart-count-down.vue'
import MallFullPromotions from '../full-promotions.vue'
import type { AS_MiniCart } from '../../../../types/index'

const analysisInstance = inject<AS_MiniCart.AnalysisInstance>('analysisInstance')
const { vExpose } = analysisInstance as AS_MiniCart.AnalysisInstance

const props = defineProps({
  mallCode: {
    type: String,
    default: '',
  },
  mallName: {
    type: String,
    default: '',
  },
  mallDesc: {
    type: String,
    default: '',
  },
  promotionData: {
    type: Array,
    default: () => [],
  },
  shippingActivityData: {
    type: Object,
    default: () => {},
  },
  language: {
    type: Object,
    default: () => {},
  },
  showNewUserTag: {
    type: Boolean,
    default: false,
  },
})

const firstFreeShipping = computed(() => { // 首单免邮
  return props.shippingActivityData?.freeType == 1 ? props.shippingActivityData || {} : {}
})

const shippingTip = computed(() => { // 平台型免邮文案
  return props.shippingActivityData?.tip || ''
})

const analysisDataEvent = (id: AS_MiniCart.Events) => {
  const { is_fullshippingactivity, mallCode, id: shipping_method } = props.shippingActivityData
  return {
    id,
    data: {
      is_fullshippingactivity: is_fullshippingactivity,
      mall_code: mallCode || '-',
      origin_price: props.shippingActivityData?.originPrice?.amount || '-',
      shipping_method: shipping_method || '-',
      show_position: 6,
      shipping_free_type: 3,
      type: 'activity',
      store_code: '-',
    },
  }
}
</script>

<style lang="less">
.bsc-mini-cart-mall-header {
  margin-top: 10px;
  padding-bottom: 10px;
  background: #fff;
  &:first-child {
    margin-top: 0;
  }
  .bsc-mini-cart-mall-header__content {
    font-size: 16px;
    color: #222222;
    font-weight: 900;
    display: flex;
    font-family: 'Arial-Black', 'Arial';
    overflow: hidden;
    background-image: url("https://img.ltwebstatic.com/images3_ccc/2024/09/25/61/1727231181e6513d89edc63eaa109fe6c4fdb2d9d1.png");
    background-size: cover;
    height: 37px;
    line-height: 37px;
    padding: 0 20px;
    .bsc-mini-cart-mall-header__title-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      max-width: 322px;
      margin-right: 4px;
    }
  }
  .bsc-mini-cart-mall-header__title {
    display: flex ;
    flex: 1;
    align-items: center;
    overflow: hidden;
    .bsc-mini-cart-mall-header__title-tag {
      flex: 1;
      min-width: 20px;
      position: relative;
      top: 2px;
    }
  }
  .bsc-mini-cart-mall-header__shipping {
    background: #F5FCFB;
    padding: 10px;
    color: #222222;
    font-weight: 400;
    font-size: 12px;
    word-break: break-word;
  }
  .bsc-mini-cart-full-promotion-item {
    margin: 10px 20px 0px;
    border-bottom: none;
    + .bsc-mini-cart-full-promotion-item {
      margin-top: 5px;
    }
  }
}
</style>
