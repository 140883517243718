<template>
  <div class="bsc-mini-cart-store-header">
    <slot></slot>
    <div
      class="bsc-mini-cart-store-header__right"
      @click="toShop"
    >
      <img
        :src="storeLogo"
        alt=""
        class="bsc-mini-cart-store-header__logo"
      />
      <span class="bsc-mini-cart-store-header__title">{{ shopName }}</span>
      <img
        v-if="!!storeTrendLogo"
        class="bsc-mini-cart-store-header__trend-icon"
        :src="storeTrendLogo"
      />
      <Icon
        v-if="shopName.toLowerCase() != 'shein' && storeCode != 'shein' && storeCode != '1' && isSheinStore != '1'"
        name="sui_icon_more_right_12px"
        size="12px"
        :is-rotate="cssRight"
      />
    </div>
  </div>
</template>

<script name="BMiniCartStoreHeader" setup lang="ts">
import { stringifyQueryString } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  shopName: {
    type: String,
    default: '',
  },
  storeCode: {
    type: String,
    default: '',
  },
  storeType: {
    type: [String, Number],
    default: '',
  },
  storeTrendLogo: {
    type: String,
    default: '',
  },
  preferredSellerStore: {
    type: [String, Number],
    default: 0,
  },
  storeRouting: {
    type: String,
    default: '',
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  storeLogo: {
    type: String,
    default: '',
  },
  isSheinStore: {
    type: String,
    default: '0',
  },
})

const toShop = () => {
  if (props.storeRouting){
    let url = props.storeRouting + (props.storeRouting.indexOf('?') > -1 ? '&' : '?') + stringifyQueryString({
      queryObj: {
        rule_poskey: 'CartShopItemList',
      },
    })
    window.open(url, '_blank' )
  }
}
</script>


<style lang="less">
.bsc-mini-cart-store-header {
  padding: 1px 20px 10px;
  cursor: pointer;
  white-space: nowrap;
  line-height: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  background: #fff;
  &:first-child {
    padding-top: 20px;
  }
  .bsc-mini-cart-store-header__right {
    display: flex;
    align-items: center;
    flex-grow: 0;
    overflow: hidden;
    color: #222222;
    .bsc-mini-cart-store-header__title {
      font-size: 12px;
      line-height: 15px;
      padding: 0 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .bsc-mini-cart-store-header__logo {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
    .bsc-mini-cart-store-header__trend-icon {
      width: 44px;
      height: 14px;
    }
  }
}
</style>
