import { getStepsByApiData } from './format'

import type { CartType, CartListener, CartManagerHooks, DataByBff, TagTip } from '../types'

class CartManager {
  HeaderCarts: CartListener[] = []
  FooterCarts: CartListener[] = []
  QuickCarts: CartListener[] = []
  hooks?: CartManagerHooks

  constructor(hooks?: CartManagerHooks) {
    this.hooks = hooks
  }

  register(type: CartType, listener: CartListener, config?: { only?: boolean }) {
    let target
    switch (type) {
      case 'header-cart':
        if (config?.only) {
          this.HeaderCarts = [listener]
        } else {
          target = this.HeaderCarts.find(fn => fn === listener)
          if (target) return
          this.HeaderCarts.push(listener)
        }
        break
      case 'footer-cart':
        if (config?.only) {
          this.FooterCarts = [listener]
        } else {
          target = this.FooterCarts.find(fn => fn === listener)
          if (target) return
          this.FooterCarts.push(listener)
        }
        break
      case 'quick-cart':
        if (config?.only) {
          this.QuickCarts = [listener]
        } else {
          target = this.QuickCarts.find(fn => fn === listener)
          if (target) return
          this.QuickCarts.push(listener)
        }
        break
      default:
        throw new Error(
          `this is an invalid type: ${type}. valid types: header-cart, footer-cart, quick-cart`,
        )
    }
  }

  unregister(cartType: CartType, listener: CartListener) {
    switch (cartType) {
      case 'header-cart':
        this.HeaderCarts = this.HeaderCarts.filter(fn => fn !== listener)
        break
      case 'footer-cart':
        this.FooterCarts = this.FooterCarts.filter(fn => fn !== listener)
        break
      case 'quick-cart':
        this.QuickCarts = this.QuickCarts.filter(fn => fn !== listener)
        break
      default:
        throw new Error(
          `this is an invalid type: ${cartType}. valid types: header-cart, footer-cart, quick-cart`,
        )
    }
  }

  triggerQuickCartsShowtime(steps: TagTip.Step[], config?: TagTip.Config) {
    if (!this.QuickCarts.length) return

    this.QuickCarts.forEach(cartListener => {
      cartListener.showtime?.(steps, config)
    })
  }

  triggerHeaderCartsShowtime(steps: TagTip.Step[], config?: TagTip.Config) {
    if (!this.HeaderCarts.length) return

    this.HeaderCarts.forEach(cartListener => {
      cartListener.showtime?.(steps, config)
    })
  }

  triggerFooterCartsShowtime(steps: TagTip.Step[], config?: TagTip.Config) {
    if (!this.FooterCarts.length) return

    this.FooterCarts.forEach(cartListener => {
      cartListener.showtime?.(steps, config)
    })
  }

  triggerQuickCartsShowtimeByBff(dataByBff: DataByBff, config?: TagTip.Config) {
    if (!this.QuickCarts.length) return

    this.QuickCarts.forEach(cartListener => {
      cartListener.showtimeByBff?.(dataByBff, config)
    })

    this.runHooksByBff(dataByBff, config)
  }

  triggerHeaderCartsShowtimeByBff(dataByBff: DataByBff, config?: TagTip.Config) {
    if (!this.HeaderCarts.length) return

    this.HeaderCarts.forEach(cartListener => {
      cartListener.showtimeByBff?.(dataByBff, config)
    })

    this.runHooksByBff(dataByBff, config)
  }

  triggerFooterCartsShowtimeByBff(dataByBff: DataByBff, config?: TagTip.Config) {
    if (!this.FooterCarts.length) return

    this.FooterCarts.forEach(cartListener => {
      cartListener.showtimeByBff?.(dataByBff, config)
    })

    this.runHooksByBff(dataByBff, config)
  }

  triggerShowtime(steps: TagTip.Step[], config?: TagTip.Config) {
    // steps 可能为空数组

    // TODO: 兼容旧的数据格式，BFF全量后删除
    if (!steps.length) {
      steps.push({
        // @ts-ignore
        type: 'default',
        // @ts-ignore
        realType: '',
        tag: false,
        tips: '',
        category: null,
      })
    }
    if (this.QuickCarts.length) {
      this.triggerQuickCartsShowtime(steps, {
        callback: () => {
          if (this.HeaderCarts.length) {
            const lastStep = [...steps].pop()
            if (lastStep)
              this.triggerHeaderCartsShowtime([{ ...lastStep, noTips: true }])
          }
          config?.callback?.()
        },
      })
      return
    }
    if (this.FooterCarts.length) {
      this.triggerFooterCartsShowtime(steps, {
        callback: () => {
          if (this.HeaderCarts.length) {
            const lastStep = [...steps].pop()
            // TODO steps 可能为空数组
            if (lastStep) {
              this.triggerHeaderCartsShowtime([{ ...lastStep, noTips: true }])
            }
          }
          config?.callback?.()
        },
      })
      return
    }
    if (this.HeaderCarts.length) {
      const step1 = steps[0]
      // 兜底：header-cart 只有 freeshipping, save, gift 利诱
      if (!['freeshipping', 'save', 'gift'].includes(step1?.realType)) {
        this.triggerHeaderCartsShowtime(
          [
            {
              // @ts-ignore
              type: 'default',
              // @ts-ignore
              realType: '',
              tag: false,
              totalPrice: step1?.totalPrice,
              tip: step1?.tip,
              tips: step1?.tips,
              noTips: step1?.noTips,
              category: null,
            },
          ],
          {
            callback: config?.callback,
          },
        )
        return
      }
      // 兜底：复杂标签气泡不展示，直接展示最终的标签
      if (step1?.tips && typeof step1.tips === 'object') {
        if (step1.type === 'gift') {
          this.triggerHeaderCartsShowtime([{
            ...step1,
            tips: step1.tip || '',
          }], {
            callback: config?.callback,
          })
          return
        }
        this.triggerHeaderCartsShowtime([steps[steps.length - 1]], {
          callback: config?.callback,
        })
        return
      }

      this.triggerHeaderCartsShowtime(steps, {
        callback: config?.callback,
      })
      return
    }
  }

  triggerShowtimeByBff(dataByBff: DataByBff, config?: TagTip.Config) {
    if (this.QuickCarts.length) {
      this.triggerQuickCartsShowtimeByBff(dataByBff, {
        ...config,
        callback: () => {
          if (this.HeaderCarts.length) {
            this.triggerHeaderCartsShowtimeByBff(dataByBff, { ...config, noTips: true })

            // 兼容 header cart showtime
            const steps = getStepsByApiData(dataByBff.appStyleControl, dataByBff.totalPrice)
            const lastStep = steps.pop() || {} as TagTip.Step
            this.triggerHeaderCartsShowtime([{ ...lastStep, noTips: true }])
          }

          config?.callback?.()
        },
      })
      return
    }
    if (this.FooterCarts.length) {
      this.triggerFooterCartsShowtimeByBff(dataByBff, {
        ...config,
        callback: () => {
          if (this.HeaderCarts.length) {
            this.triggerHeaderCartsShowtimeByBff(dataByBff, { ...config, noTips: true })

            // 兼容 header cart showtime
            const steps = getStepsByApiData(dataByBff.appStyleControl, dataByBff.totalPrice)
            const lastStep = steps.pop() || {} as TagTip.Step
            this.triggerHeaderCartsShowtime([{ ...lastStep, noTips: true }])
          }

          config?.callback?.()
        },
      })
      return
    }

    if (this.HeaderCarts.length) {
      this.triggerHeaderCartsShowtimeByBff(dataByBff, {
        ...config,
        callback: config?.callback,
      })
      return
    }
  }

  triggerQuickCartsEndtime(config) {
    this.QuickCarts.forEach(cartListener => {
      cartListener.endtime?.(config)
    })
  }

  triggerHeaderCartsEndtime() {
    this.HeaderCarts.forEach(cartListener => {
      cartListener.endtime?.()
    })
  }

  triggerFooterCartsEndtime() {
    this.FooterCarts.forEach(cartListener => {
      cartListener.endtime?.()
    })
  }

  triggerEndtime() {
    this.triggerFooterCartsEndtime()
    this.triggerHeaderCartsEndtime()
  }

  runHooksByBff(dataByBff: DataByBff, config?: TagTip.Config) {
    if (this.hooks?.success) {
      if (config?.noTips) return
      // TODO 可优化
      if (!dataByBff.appStyleControl?.bubble) return
      this.hooks.success()
    }
  }
}

export default CartManager
