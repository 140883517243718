<template>
  <div class="bsc-mini-cart-list">
    <template
      v-for="mallCartItem in validMallCarts"
      :key="mallCartItem.mall_code"
    >
      <MallHeader
        v-if="isPlatform"
        :mall-code="mallCartItem.mall_code"
        :mall-name="mallCartItem.mall_name"
        :mall-desc="mallCartItem.mallDesc"
        :language="language"
        :shipping-activity-data="mallCartItem.shippingActivityData"
        :promotion-data="mallCartItem.promotionData"
        :show-new-user-tag="showNewUserTag"
      >
        <SCheckbox
          class="bsc-mini-cart-list__checkbox"
          :label="mallCartItem.mall_code"
          :model-value="mallCartItem.is_checked == 1"
          gap="0px"
          size="size16"
          outside-check
          @change="handleGroupCheckedItem(mallCartItem, $event)"
        />
      </MallHeader>
      <template
        v-for="(shop, i) in mallCartItem.shops"
        :key="mallCartItem.mall_code+'-'+shop.store_code + '-' + shop.shopName"
      >
        <template v-if="shop.store_code && (i < renderSize)">
          <StoreHeader
            v-expose="getExposeStoreNameData(shop)"
            v-tap="getClickStoreNameData(shop)"
            :store-code="shop.store_code"
            :shop-name="shop.shopName"
            :store-type="shop.store_type"
            :store-trend-logo="shop.store_trend_logo"
            :preferred-seller-store="shop.preferred_seller_store"
            :store-routing="shop.storeRouting"
            :css-right="appConfigs.$envs.cssRight"
            :store-logo="shop.store_logo"
          >
            <SCheckbox
              class="bsc-mini-cart-list__checkbox"
              style="vertical-align: text-bottom;"
              :label="mallCartItem.mall_code +','+shop.store_code"
              :model-value="shop.is_checked == 1"
              gap="0px"
              size="size16"
              outside-check
              @change="handleGroupCheckedItem(shop, $event)"
            />
          </StoreHeader>
          <StoreShipping
            v-if="shop?.shippingActivityData?.tip"
            :shipping-activity-data="shop.shippingActivityData"
            :css-right="appConfigs.$envs.cssRight"
            :mall-code="mallCartItem.mall_code"
            :language="language"
          />
        </template>
        <template
          v-for="(content, index) in shop.contentData"
          :key="mallCartItem.mall_code+'-'+shop.store_code + '-' + shop.shopName+ '-' + index"
        >
          <PromotionHeader
            v-if="content.groupHeadInfo && content.groupHeadInfo.data"
            :language="language"
            :promotion-group-head-info="content.groupHeadInfo"
            :promotion-event-tracking-point="promotionEventTrackingPoint"
            :css-right="appConfigs.$envs.cssRight"
            :store-code="shop.store_code"
          >
            <SCheckbox
              v-if="content.groupHeadInfo.data.is_shop_group != 1"
              class="bsc-mini-cart-list__checkbox"
              :label="content.groupHeadInfo.data.promotion_id"
              :model-value="content.groupHeadInfo.data.is_checked == 1"
              gap="0px"
              size="size16"
              outside-check
              @change="handleGroupCheckedItem(content.groupHeadInfo.data, $event)"
            />
          </PromotionHeader>
          <div
            v-else
            :key="'common-'+ shop.store_code + '-' + mallCartItem.mall_code"
            class="bsc-mini-cart-common-group-header"
          ></div>
          <div
            v-for="item in content.productLineInfoList.filter(i => cartIds.indexOf(i.id) < renderSize)"
            :key="item.id"
            class="bsc-mini-cart-list__item"
          >
            <div
              class="bsc-mini-cart-list__item-checked"
              :style="{ opacity: (item.aggregateProductBusiness.isPresent == '1' || item.isPayMemberGift == '1') ? 0 : 1 }"
            >
              <SCheckbox
                :label="item.id"
                :disabled="item.isDisabled"
                :model-value="item.is_checked == 1"
                gap="0"
                size="size16"
                outside-check
                @change="handleCheckedItem(item, $event)"
              />
            </div>
            <div class="bsc-mini-cart-list__item-content">
              <BMiniCartItem
                :key="item.id"
                :data="item"
                :config="config"
                :constant="constant"
                :language="language"
                :use="use"
                @expose="handleExposeItem(item)"
              />
            </div>
          </div>
        </template>
      </template>
    </template>
    <template v-if="soldoutItems && soldoutItems.length">
      <div class="bsc-mini-cart-sold-out-header">
        {{ language.SHEIN_KEY_PC_30886 }}
      </div>
      <div
        v-for="item in soldoutItems.filter(i => cartIds.indexOf(i.id) < renderSize)"
        :key="item.id"
        class="bsc-mini-cart-list__item"
      >
        <div class="bsc-mini-cart-list__item-content">
          <BMiniCartItem
            :key="item.id"
            :data="item"
            :config="config"
            :constant="constant"
            :language="language"
            :use="use"
            @expose="handleExposeItem(item)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script name="BMiniCartList" setup lang="ts">
import { computed, ref, watch, inject,onMounted } from 'vue'
import MallHeader from './mall-header.vue'
import StoreHeader from './store-header.vue'
import PromotionHeader from './promotion-header.vue'
import StoreShipping from './store-shipping.vue'
import { BMiniCartItem, isCustomizationProduct, type Laptop, getConstant } from '@shein-aidc/bs-cart-item-laptop'
import { SToast } from '@shein-aidc/sui-toast/laptop'
import { SCheckbox } from '@shein-aidc/sui-checkbox/laptop'
import { getGoodsUrl } from '../../utils/index'
import { SMessage } from '@shein-aidc/sui-message/laptop'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { AS_MiniCart } from '../../../../types/index'

const appConfigs = useAppConfigs()

defineProps({
  showNewUserTag: {
    type: Boolean,
    default: false,
  },
})

const soldoutItems = inject<any[]>('soldoutItems')
const isPlatform = inject('isPlatform')
const language = inject<any>('language')
const cartInfo = inject<any>('cartInfo')
const carts = inject<any>('carts')
const priceMode = inject<any>('priceMode')
const modifyCartCheckStatus = inject('modifyCartCheckStatus') as Function
const cartUpdate = inject('cartUpdate') as Function
const cartDelete = inject('cartDelete') as Function
const validMallCarts = inject<any>('validMallCarts')
const analysisInstance = inject<AS_MiniCart.AnalysisInstance>('analysisInstance')
const onBeforeClickModifyCheckbox = inject('onBeforeClickModifyCheckbox') as Function
const onAfterClickModifyCheckbox = inject('onAfterClickModifyCheckbox') as Function
const renderSize = inject<any>('renderSize')

const { vExpose, vTap } = analysisInstance as any

// data
// 商品行常量配置
const constant = ref(getConstant())

const promotionEventTrackingPoint = computed(() => {
  return cartInfo.value.promotionEventTrackingPoint || []
})

const cartIds = computed(() => {
  return carts.value.map(item => item.id)
})


watch(() => cartInfo.value, () => {
  estimateCountdownReport()
})

watch(() => carts.value, (v) => {
  if (v && v.length) {
    const existDiscount = v.some(item => item?.product?.unit_discount != 0)
    analysisInstance?.triggerNotice({
      id: 'expose_scenesabtest.comp_mini-cart',
      data: {
        scenes: 'add_discount_logo_switch',
        type: existDiscount ? 1 : 0,
      },
    })
  }
}, { immediate: true })

onMounted(() => {
  estimateCountdownReport()
})

const estimateCountdownReport = () => {
  const flag = carts.value.some(item => item.aggregateProductBusiness?.estimatedPriceEndTimeStamp)
  analysisInstance?.triggerNotice({
    id: 'expose_scenesabt.comp_mini-cart',
    data: {
      scenes: 'minicart_estimated_countdown',
      type: flag ? 1 : 0,
    },
  })
}


const handleGroupCheckedItem = (item, { checked = false }) => {
  onBeforeClickModifyCheckbox(checked ? 1 : 0, 'section')
  let reqParam = {
    operation_type: checked ? 1 : 2,
    cart_id_list: item.cart_id_list,
    append_id_list: [],
  }

  if (checked) {
    reqParam.append_id_list = item.append_id_list
  }
  analysisInstance?.triggerNotice({
    id: 'click_select_someproduct.comp_mini-cart',
    data: {
      select_type: 2,
      is_select: checked ? 0 : 1,
      position: 'page',
    },
  })
  modifyCartCheckStatus(reqParam).then((res) => {
    onAfterClickModifyCheckbox(checked ? 1 : 0, 'section', res?.code == 0 ? 1 : 0)
  })
}

const handleCheckedItem = (item, { checked = false }) => {
  onBeforeClickModifyCheckbox(checked ? 1 : 0, 'item')
  if(parseInt(item.quantity) > parseInt(item.realTimeInventory)){
    SMessage({
      message: language.value.SHEIN_KEY_PC_21623,
      type: 'error',
    })
    return
  }
  let reqParam = {
    operation_type: checked ? 1 : 2,
    cart_id_list: [] as any[],
    append_id_list: [] as any[],
  }
  reqParam.cart_id_list.push(item.id)
  if (checked) {
    reqParam.append_id_list = reqParam.append_id_list.concat(item.appendIds || [])
  }

  analysisInstance?.triggerNotice({
    id: 'click_select_someproduct.comp_mini-cart',
    data: {
      select_type: 2,
      is_select: checked ? 0 : 1,
      position: 'page',
    },
  })

  modifyCartCheckStatus(reqParam).then((res) => {
    onAfterClickModifyCheckbox(checked ? 1 : 0, 'item', res?.code == 0 ? 1 : 0)
  })
}

const handleExposeItem = (item) => {
  analysisInstance?.triggerNotice({
    id: 'expose_cartpromotionadd.comp_mini-cart',
    data: {
      typeId: item.aggregateProductBusiness.type_id || '',
      isFullPromotion: '0',
      activity_id: item.aggregateProductBusiness.promotion_id || '',
      goods_sn: item.product?.goods_sn || '',
      newuser_label: '-',
      is_member_gift: item.aggregateProductBusiness.isPayMemberGift || '0',
    },
  })
  analysisInstance?.triggerNotice({
    id: 'expose_flash_link.comp_mini-cart',
    data: {
      flashTypes: [item.aggregateProductBusiness.flash_type || '0'],
    },
  })
}

const getItemIndex = (id) => {
  return carts.value.findIndex(cart => cart.id == id)
}
const loading = ref(false) // 用于删除、更改数量操作的loading
// 商品行配置
const use = computed(() => {
  const defaultUse: Laptop.MiniCart[] = [
    'BehaviorLabel',
    'EstimatedPrice',
    'DiscountNormalTag',
    'DiscountLimitTag',
    'GoodsAlert',
    'GoodsBelt',
    'GoodsDiscountFlag',
    'GoodsImg',
    'GoodsNegativeInfo',
    'GoodsPrice',
    'GoodsQty',
    'GoodsSaleAttr',
    'GoodsSeriesFlag',
    'GoodsStore',
    'QuickShipTag',
    'FreeShippingTag',
    'EvoluTag',
    'VoucherTag',
    'PromotionTag',
    'InsurePriceTag',
    'GoodsTitle',
    'GoodsTitleIcon',
    'ReducePriceHint',
    'GoodsDeleteButton',
    'SFSTag',
    'HolidayDeliveryTag',
    'RankListTag',
    'BrandDealsTag',
  ]
  return defaultUse
})
const config = ref<Laptop.Config<Laptop.MiniCart>>({
  // 用户行为标签
  BehaviorLabel: {
    props() {
      return {
        isSwiper: false,
      }
    },
  },
  // 信息提示组件
  GoodsAlert: {
    props() {
      return {
        // mini车不展示重新选择提示语
        reselectTip: '',
        // mini车不提示切 MALL 提示
        changeMallTip: '',
      }
    },
  },
  // 删除按钮
  GoodsDeleteButton: {
    events: {
      async onClick({ data }) {
        if (loading.value) return
        loading.value = true
        const reqParam = {
          ids: [data.value.id],
        }
        const res = await cartDelete(reqParam).finally(() => loading.value = false)
        analysisInstance?.triggerNotice({
          id: 'click_goods_delete.comp_mini-cart',
          data: {
            result: res.code == 0 ? 1 : 0,
          },
        })
      },
    },
  },
  // 商品图片
  GoodsImg: {
    props({ data }) {
      let isFirstPage = getItemIndex(data.value.id) < 6
      return {
        isCutImg: isFirstPage,
        isLazyLoad: !isFirstPage,
      }
    },
    events: {
      onClick({ data }) {
        const payMemberGiftInvalidTip = data.value.aggregateProductBusiness.productTags?.find(tag => tag.type === 'payMemberGift' && tag.style == '-1')?.view?.text || ''
        // 付费会员赠品失效
        if (data.value?.isPayMemberGift == '1' && data.value?.isInvalid == '1' && payMemberGiftInvalidTip) {
          SToast({
            content: payMemberGiftInvalidTip,
          })
          return
        }
        const url = getGoodsUrl(data.value.product?.goods_url_name, data.value.product?.goods_id)
        window.location.href = url
      },
    },
  },
  // 价格
  GoodsPrice: {
    props({ data }) {
      return {
        isShowOriginalPrice: priceMode.value !== 'normal' && !!data.value.aggregateProductBusiness?.priceData?.originalPrice,
      }
    },
  },
  // 数量编辑器
  GoodsQty: {
    position: 'up',
    props() {
      return {
        isShow: true,
        width: '75px',
        height: '20px',
        padding: '0 6px',
        tipsMaxWidth: '100px',
        autoAlignByShowInputTips: true,
      }
    },
    events: {
      onChange({ data, event }) {
        if (loading.value) return
        loading.value = true
        const { quantity, action } = event
        const oldQuantity = data.value.quantity
        data.value.quantity = String(quantity)
        const onError = () => {
          data.value.quantity = oldQuantity
        }
        const reqParam = {
          quantity: String(quantity),
          cart_id: data.value.id,
          append_id_list: data.value.appendIds || [],
          is_checked: data.value.is_checked,
        }
        cartUpdate(reqParam)
          .then((res) => {
            if (res.code != 0) {
              SMessage({
                message: res.msg,
                type: 'warning',
              })
              onError()
            }
            const result = res.code == 0 ? 1 : 0
            if (action === 'input') {
              analysisInstance?.triggerNotice({
                id: 'click_update_quantity.comp_mini-cart',
                data: {
                  skc: data.value?.product?.goods_sn || '',
                  sku: data.value?.product?.sku_code || '',
                  result,
                },
              })
            } else {
              analysisInstance?.triggerNotice({
                id: 'click_quantity_edit_select_button.comp_mini-cart',
                data: {
                  skc: data.value?.product?.goods_sn || '',
                  sku: data.value?.product?.sku_code || '',
                  result,
                },
              })
            }
          })
          .catch(onError)
          .finally(() => loading.value = false)
      },
      onReport({ data, event }) {
        const { type } = event
        if (type === 'clickInput') {
          analysisInstance?.triggerNotice({
            id: 'click_quantity_edit_Input_button.comp_mini-cart',
            data: {
              skc: data.value?.product?.goods_sn || '',
              sku: data.value?.product?.sku_code || '',
            },
          })
        } else if (type === 'clickNullValueOption') {
          analysisInstance?.triggerNotice({
            id: 'click_quantity_edit_select_button.comp_mini-cart',
            data: {
              skc: data.value?.product?.goods_sn || '',
              sku: data.value?.product?.sku_code || '',
              result: '-',
            },
          })
        }
      },
      onExpose({ data }) {
        analysisInstance?.triggerNotice({
          id: 'expose_quantity_edit_Input_button.comp_mini-cart',
          data: {
            skc: data.value?.product?.goods_sn || '',
            sku: data.value?.product?.sku_code || '',
          },
        })
      },
    },
  },
  // 销售属性
  GoodsSaleAttr: {
    props({ data }) {
      // 颜色图
      const colorImage = data.value.product?.color_image
      // 尺码
      const goodsAttr = data.value.aggregateProductBusiness.goodsAttr
      // 新人专享商品
      const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
      // 是否赠品
      const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
      // 是否选择
      const isNotChecked = data.value.is_checked === '0'
      // 是否自定义商品
      const isCustomGoods = isCustomizationProduct(data.value)
      // 失效商品
      const isInvalidItem = data.value.isInvalid === '1'

      // 是否显示蒙层
      let isShowMask = false
      if (isCustomGoods || isNewcomerItem || (isGiftItem && isNotChecked) || isInvalidItem) {
        isShowMask = true
      }
      return {
        isShow: !!(colorImage || goodsAttr),
        type: 'text',
        height: '20px',
        size: '12px',
        isClick: false,
        mode: 'attr',
        color: '#666',
        fontWeight: 'normal',
        isShowMask,
      }
    },
  },
  // 行内店铺头
  GoodsStore: {
    props() {
      return {
        storeIconSize: '14px',
        textFontSize: '11px',
      }
    },
    events: {
      onClick({ data }) {
        if (data.value.storeRouting) {
          window.open(data.value.storeRouting, '_blank' )
        }
      },
      onExpose({ data }) {
        analysisInstance?.triggerNotice(getExposeStoreNameData(data.value))
      },
      onReport({ data }) {
        analysisInstance?.triggerNotice(getClickStoreNameData(data.value))
      },
    },
  },
  // 活动标签
  PromotionTag: {
    events: {
      async onClick({ data, event }) {
        let { flashType, typeId, jumpLink = '' } = event?.data?.[event?.type] || {}
        // 付费会员赠品标签点击
        if (event?.type == 'payMemberGift') {
          jumpLink && window.open(jumpLink, '_blank')
          return
        }
        // 其他标签点击
        const index = getItemIndex(data.value.id)
        if (typeId == '10' && flashType == '6') {
          jumpLink = jumpLink.replace('{ps}', index + 1).replace('{src_tab_page_id}', '')
        }
        if(typeId == '30') {
          jumpLink = jumpLink.replace('{ici}', 'MiniCart').replace('{src_tab_page_id}', '').replace('{ps}', index + 1)
        }
        const newWin = window.open('', '_blank')
        if (jumpLink) {
          newWin && (newWin.location = jumpLink)
        }
      },
      onReport({ data, event }) {
        const index = getItemIndex(data.value.id)
        const { promotionId, ruleNum = '', typeId, flashType = '0' } = event?.data?.[event?.type] || {}
        const content_list = `underPrice_${ruleNum}`
        if (!['32'].includes(typeId)) {
          analysisInstance?.triggerNotice({
            id: 'click_promotion_block.comp_mini-cart',
            data: {
              promotion_id: promotionId,
              promotion_tp_id: typeId,
              content_list,
              src_module: 'promotion',
              src_identifier: `on=${typeId}\`cn=${promotionId}\`ps=${index + 1}_1\`jc=${content_list}`,
              src_tab_page_id: '',
            },
          })
          if (['10'].includes(typeId)) {
            analysisInstance?.triggerNotice({
              id: 'click_flash_link.comp_mini-cart',
              data: {
                flashTypes: [flashType],
              },
            })
          }
          if (['3', '10'].includes(typeId)) {
            analysisInstance?.triggerNotice({
              id: 'click_cartpromotionadd.comp_mini-cart',
              data: {
                typeId,
                isFullPromotion: 0,
                goods_sn: data.value?.product?.goods_sn || '',
                activity_id: promotionId,
                is_member_gift: 0,
              },
            })
          }
          if (event?.type == 'payMemberGift') {
            analysisInstance?.triggerNotice({
              id: 'click_membergift_tag.comp_mini-cart',
              data: {
                goods_id: data.value?.product.goods_id,
              },
            })
          }
        }
      },
      onExpose({ data, event }) {
        const index = getItemIndex(data.value.id)
        const { promotionId, ruleNum = '', typeId, flashType = '0' } = event?.data?.[event?.type] || {}
        const content_list = `underPrice_${ruleNum}`
        if (!['32'].includes(typeId)) {
          analysisInstance?.triggerNotice({
            id: 'expose_promotion_block.comp_mini-cart',
            data: {
              promotion_id: promotionId,
              promotion_tp_id: typeId,
              content_list,
              src_module: 'promotion',
              src_identifier: `on=${typeId}\`cn=${promotionId}\`ps=${index + 1}_1\`jc=${content_list}`,
              src_tab_page_id: '',
            },
          })
          if (['3', '10'].includes(typeId)) {
            analysisInstance?.triggerNotice({
              id: 'expose_cartpromotionadd.comp_mini-cart',
              data: {
                typeId,
                isFullPromotion: 0,
                activity_id: promotionId,
                goods_sn: data.value?.product?.goods_sn || '',
                newuser_label: '',
                is_member_gift: event?.type == 'payMemberGift' ? 1 : 0,
              },
            })
          }
          if (['10'].includes(typeId)) {
            analysisInstance?.triggerNotice({
              id: 'expose_flash_link.comp_mini-cart',
              data: {
                flashTypes: [flashType],
              },
            })
          }
        }

        if (['32'].includes(typeId)) {
          analysisInstance?.triggerNotice({
            id: 'expose_cartpromotionadd.comp_mini-cart',
            data: {
              typeId,
              isFullPromotion: 0,
              activity_id: promotionId,
              goods_sn: data.value?.product?.goods_sn || '',
              newuser_label: '',
              is_member_gift: event?.type == 'payMemberGift' ? 1 : 0,
            },
          })
        }
      },
    },
  },
  InsurePriceTag: {
    events: {
      onExpose({ data}) {
        analysisInstance?.triggerNotice({
            id: 'expose_insured_goods.comp_mini-cart',
            data: {
              is_insured_goods: data.value?.product?.goods_id,
            },
          })
      }
    }
  },
  // 商品标题
  GoodsTitle: {
    props() {
      return {
        hoverStyle: 'LINE',
      }
    },
    events: {
      onClick({ data }) {
        const payMemberGiftInvalidTip = data.value.aggregateProductBusiness.productTags?.find(tag => tag.type === 'payMemberGift' && tag.style == '-1')?.view?.text || ''
        // 付费会员赠品失效
        if (data.value?.isPayMemberGift == '1' && data.value?.isInvalid == '1' && payMemberGiftInvalidTip) {
          SToast({
            content: payMemberGiftInvalidTip,
          })
          return
        }
        const url = getGoodsUrl(data.value.product?.goods_url_name, data.value.product?.goods_id)
        window.location.href = url
      },
    },
  },
  // 商品降价提醒
  ReducePriceHint: {
    props() {
      return {
        isShowPrefixIcon: false,
        textColor: '#ff7300',
      }
    },
  },
  GoodsNegativeInfo: {
    props() {
      return {
        trigger: 'Observer',
      }
    },
  },
})

const getExposeStoreNameData = (data): { id: AS_MiniCart.Events, data?: any } => {
  return {
    id: 'expose_store_name.comp_mini-cart',
    data: {
      store_code: data.isSheinStore == 1 ? '-' : (data.store_code || '-'),
      store_tp: data.isSheinStore == 1 ? '-' : data.store_type == 1 ? 1 : 2,
      preferred_seller: data.isSheinStore ==1 ? '-' : data.preferred_seller_store == 1 ? 1 : 0,
      store_label: data.isSheinStore == 1 ? '-' : data.store_trend_logo ? 'trends' : '-',
    },
  }
}
const getClickStoreNameData = (data): { id: AS_MiniCart.Events, data?: any } => {
  return {
    id: 'click_store_name.comp_mini-cart',
    data: {
      store_code: data.isSheinStore == 1 ? '-' : (data?.store_code || '-'),
      store_tp: data.isSheinStore == 1 ? '-' : data?.store_type == 1 ? 1 : 2,
      preferred_seller: data.isSheinStore == 1 ? '-' : data?.preferred_seller_store == 1 ? 1 : 0,
      store_label: data.isSheinStore == 1 ? '-' : data?.store_trend_logo ? 'trends' : '-',
    },
  }
}
</script>
<style lang="less">
.bsc-mini-cart-list {
  margin-bottom: 10px;
  .bsc-mini-cart-promotion-header + .bsc-mini-cart-list__item {
    padding-top: 20px;
  }
  .bsc-mini-cart-list__item + .bsc-mini-cart-common-group-header {
    padding: 0 20px;
  }
  .bsc-mini-cart-list__item + .bsc-mini-cart-promotion-header,
  .bsc-mini-cart-list__item + .bsc-mini-cart-common-group-header,
  .bsc-mini-cart-list__item + .bsc-mini-cart-store-header {
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - 50px");
      border-bottom: 1px dashed #E5E5E5;
      top: 0;
      right: 28px;
    }
  }
  .bsc-mini-cart-store-header + .bsc-mini-cart-promotion-header {
    padding-top: 0;
  }
  .bsc-mini-cart-list__checkbox {
    width: 26px;
    margin: 0;
  }
  .bsc-mini-cart-list__item + .bsc-mini-cart-store-header {
    padding-top: 20px;
  }
  .bsc-mini-cart-sold-out-header {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    padding: 16px 20px 0px;
    color: #222222;
    background: #fff;
  }
}
.bsc-mini-cart-list__item {
    display: flex;
    padding: 10px 20px;
    align-items: flex-start;
    background: #fff;
  .bsc-mini-cart-list__item-checked {
    flex-shrink: 0;
    line-height: 90px;
    .sui-checkbox {
      margin-right: 8px !important;
    }
  }
  .bsc-mini-cart-list__item-content {
    flex: 1;
    min-width: 0;
  }
}
</style>
