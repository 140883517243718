<template>
  <div
    v-if="type == 'banner' && promotionTip"
    class="bsc-mini-cart-promotion-header"
    :type-id="promotionInfo.type_id"
  >
    <!-- 横幅类型 -->
    <slot></slot>
    <img
      v-if="groupHeadStyleType == 'withImage'"
      class="promotion-header-imageStyle"
      :src="groupHeadImageInfo?.url"
      :style="{
        width: `${(+groupHeadImageInfo?.width / 3)}px`,
        height: `${(+groupHeadImageInfo?.height / 3)}px`,
      }"
    />
    <section
      v-else-if="promotionTip"
      :class="[
        'bsc-mini-cart-promotion-header__banner',
        {
          'bsc-mini-cart-promotion-header__banner_clickable': action == 1,
        }
      ]"
      tabindex="0"
      role="button"
      @click="() => clickPick()"
    >
      <p
        :class="[
          'bsc-mini-cart-promotion-header__title',
          {
            'bsc-mini-cart-promotion-header__title_red': changeColor,
          }
        ]"
      >
        <span v-html="promotionTip"></span>
        <ClientOnly>
          <MiniCartCountDown
            v-if="promotionInfo.is_count_down == 1"
            :time-stamp="promotionInfo.end_time"
            :language="language"
          />
        </ClientOnly>
      </p>
      <Icon
        v-if="(action == 1) && promotionInfo.type_id != 11"
        name="sui_icon_more_right_14px"
        size="16px"
        :is-rotate="cssRight"
      />
    </section>
  </div>

  <!-- 标题 + 提示语 -->
  <div
    v-else-if="promotionTip"
    class="bsc-mini-cart-promotion-header"
    :type-id="promotionInfo.type_id"
  >
    <slot></slot>
    <div class="bsc-mini-cart-promotion-header__content">
      <div
        class="bsc-mini-cart-promotion-header__title"
        tabindex="0"
        :aria-label="promotionTitle + ',' + promotionTip"
      >
        <span class="bsc-mini-cart-promotion-header__title-text">{{ promotionTitle }}</span>
        <a
          v-if="action > 0"
          class="bsc-mini-cart-promotion-header__title-action"
          tabindex="0"
          role="button"
          @click="() => clickPick()"
        >
          <span>{{ handleText }}</span>
          <Icon
            name="sui_icon_more_right_14px"
            size="16px"
            :is-rotate="cssRight"
          />
        </a>
      </div>
      <p
        class="bsc-mini-cart-promotion-header__desc"
        v-html="promotionTip"
      >
      </p>
      <ClientOnly>
        <MiniCartCountDown
          v-if="promotionInfo.is_count_down == 1"
          :time-stamp="promotionInfo.end_time"
          show-type="inline-promotion"
          :show-end-tips="true"
          :language="language"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script name="BMiniCartPromotionHeader" setup lang="ts">
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import MiniCartCountDown from '../mini-cart-count-down.vue'
import { PROMOTION_CONFIG } from '../../constants'
const { TitlePromotionTypeIds, BrandProductPromotionTypeIds } = PROMOTION_CONFIG

const props = defineProps({
  promotionGroupHeadInfo: {
    type: Object,
    default: function () {
      return {}
    },
  },
  promotionEventTrackingPoint: {
    type: Array,
    default: function () {
      return []
    },
  },
  language: {
    type: Object,
    default: function () {
      return {}
    },
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  storeCode: {
    type: String,
    default: '',
  },
})

const promotionInfo = computed(() => {
  return { ...props.promotionGroupHeadInfo.promotionTips || {}, ...props.promotionGroupHeadInfo.data || {} }
})
const type = computed(() => {
  const typeId = promotionInfo.value.type_id
  const BannerStyleTypeIds = [1, 9, 10, 11, 14, 15, 16, 17, 18, 19, 24, 25, 31] // 横幅类型的促销活动
  let result = 'common'
  if (BannerStyleTypeIds.includes(Number(typeId))) result = 'banner'
  return result
})
const promotionTip = computed(() => promotionInfo.value?.html_text)
const promotionTitle = computed(() => {
  let title = ''
  let { type_id, brandName = '', mainProductRange } = promotionInfo.value
  for(let key in TitlePromotionTypeIds){
    if(TitlePromotionTypeIds[key].indexOf(+promotionInfo.value.type_id) > -1){
      title = `${(mainProductRange == 4 && BrandProductPromotionTypeIds.includes(+type_id)) ? brandName + ' ' : '' }${props.language[key] || ''}`
    }
  }
  return title
})
// 0: 无操作; 1: Add，可以添加商品; 2: Pick，可以选择商品
const action = computed(() => {
  let action = 0
  const { type_id, range, next, isMeet, promotion_logo_type, isMax } = promotionInfo.value
  if (type_id == 1) {
    action = 1
  }
  if ([2, 13, 28].includes(+type_id)) {
    action = isMeet == 1 ? 2 : 1
  }
  if ([14, 15, 20, 21, 25].includes(+type_id)) {
    action = range <= 0 ? 1 : next != '0' ? 1 : 0
  }
  if (type_id == '9') {
    if (promotion_logo_type == '19') {
      action = isMax == '1' ? 0 : 1
    } else {
      action = range <= 0 ? 1 : next != '0' ? 1 : 0
    }
  }
  return action
})
// 返券活动的action只有0满足不展示、 1不满足展示add
const handleText = computed(() => {
  if (action.value == 1) {
    return props.language.SHEIN_KEY_PC_15215
  } else if (action.value == 2) {
    if (promotionInfo.value.isPicked == '1') {
      return props.language.SHEIN_KEY_PC_15969
    } else {
      return props.language.SHEIN_KEY_PC_15968
    }
  } else {
    return ''
  }
})
const changeColor = computed(() => promotionInfo.value.overLimit == '1')

/**
 * 组头样式，返回withImage表示带图片样式，返回空或normal表示线上样式
 */
const groupHeadStyleType = computed(() => {
  return promotionInfo.value?.style || ''
})

/**
 * 组头样式为withImage时，返回的图片内容和宽高等信息
 */
const groupHeadImageInfo = computed(() => {
  return promotionInfo.value?.imageInfo || {}
})

const clickPick = async () => {
  const { promotion_id: promotionId, type_id: typeId, sc_id, promotionJumpLink = '' } = promotionInfo.value

  const addGoodToReach = sc_id && action.value == 1
  const buyGiftPick = typeId == 2 && (action.value == 2)
  const addBuyPick = typeId == 13 && (action.value == 2)
  const partFullGifts = typeId == 28 && (action.value == 2)

  let type
  if (buyGiftPick) type = 'buy-gift' // 买赠
  if (addBuyPick) type = 'add-buy' // 加价购
  if (partFullGifts) type = 'full-gift' // 部分满赠

  if (type) {
    window.open('/cart', '_blank')?.location
  } else if (addGoodToReach) {
    if([13, 2, 28, 9, 25, 1, 15, 20].includes(+typeId) || (typeId == 14 && promotionInfo.value?.mainProductRange != 3)) {
      window.location.href = `/cart?promotionId=${promotionId}`
      return
    }

    if (promotionJumpLink) {
      const newWin = window.open('', '_self')
      newWin && (newWin.location = `${gbCommonInfo.langPath}${promotionJumpLink}`)
    }
  }
}
</script>

<style lang="less">
.bsc-mini-cart-promotion-header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 20px 0 20px;
  width: 100%;
  background: #fff;
  text-align: left;
  .bsc-mini-cart-promotion-header__banner,
  .bsc-mini-cart-promotion-header__content {
    flex: 1;
    padding: 10px;
    background: #FFF6F3;
    .bsc-mini-cart-promotion-header__title {
      color: #222;
    }
    .bsc-mini-cart-promotion-header__title-action {
      padding-left: 10px;
      position: static;
      white-space: nowrap;
      top: 2px;
      right: 0;
      font-weight: 700;
      cursor: pointer;
      color: #222;
      text-decoration: none;
    }
    .bsc-mini-cart-promotion-header__title-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 220px;
    }
    .bsc-mini-cart-promotion-header__desc {
      color: #222;
    }
    &.bsc-mini-cart-promotion-header__banner_clickable {
      cursor: pointer;
    }
  }
  .bsc-mini-cart-promotion-header__banner {
    display: flex;
    justify-content: space-between;
    .bsc-mini-cart-promotion-header__title_red {
      color: #d53333;
    }
  }
  .bsc-mini-cart-promotion-header__content {
    .bsc-mini-cart-promotion-header__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      font-size: 14px;
      color: #222;
      font-weight: 700;
    }
  }
}
</style>

