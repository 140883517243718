<template>
  <div
    v-expose="analysisDataEvent('expose_shipping_activity.comp_mini-cart')"
    class="bsc-mini-cartstore__shipping"
  >
    <div class="bsc-mini-cartstore__shipping-content">
      <Icon
        v-if="!showDone"
        name="sui_icon_shipping_18px"
        size="16px"
        color="#222"
      />
      <Icon
        v-else
        name="sui_icon_selected_16px"
        size="16px"
        color="#222"
      />
      <div class="bsc-mini-cartstore__shipping-desc">
        <span
          class="bsc-mini-cartstore__shipping-text"
          v-html="shippingActivityData.tip"
        >
        </span>
        <MiniCartCountDown
          v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
          class="bsc-mini-cartstore__contdown"
          :time-stamp="firstFreeShipping.countDownTime"
          :is-left-time="true"
          :language="language"
        />
      </div>
    </div>
  </div>
</template>

<script name="BMiniCartStoreShipping" setup lang="ts">
import { computed, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import MiniCartCountDown from '../mini-cart-count-down.vue'
import type { AS_MiniCart } from '../../../../types/index'

const analysisInstance = inject<AS_MiniCart.AnalysisInstance>('analysisInstance')
const { vExpose } = analysisInstance as AS_MiniCart.AnalysisInstance

const props = defineProps({
  shippingActivityData: {
    type: Object,
    default: () => ({}),
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  mallCode: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => {},
  },
})

const firstFreeShipping = computed(() => { // 首单免邮
  return props.shippingActivityData?.freeType == 1 ? props.shippingActivityData || {} : {}
})
const showDone = computed(() => props.shippingActivityData?.is_fullshippingactivity == 1)

const analysisDataEvent = (id: AS_MiniCart.Events) => {
  const { freeShippingScope, is_fullshippingactivity, id: shipping_method, storeCode } = props.shippingActivityData
  const isFullStore = freeShippingScope === 'fullStore'
  const isStore = freeShippingScope === 'store'
  const isMall = freeShippingScope === 'mall'
  return {
    id,
    data: {
      is_fullshippingactivity: isFullStore ? '1' : is_fullshippingactivity,
      mall_code: props.mallCode || '-',
      origin_price: isFullStore ? '-' : (props.shippingActivityData?.originPrice?.usdAmountWithSymbol || '-'),
      shipping_method: (isFullStore || isStore) ? '-' : (shipping_method || '-'),
      show_position: 5,
      shipping_free_type: isMall ? 3 : 4,
      type: isFullStore ? 'allshop' : 'activity',
      store_code: (isFullStore || isStore) ? storeCode : '-',
    },
  }
}
</script>

<style lang="less">
.bsc-mini-cartstore {
  &__shipping {
    padding: 0 20px 0 20px;
    background: #fff;
  }
  &__shipping-content {
    display: flex;
    align-items: center;
    background: #F5FCFB;
    padding: 10px;
  }
  &__shipping-desc {
    margin-left: 8px;
  }
  &__shipping-text {
    flex: 1;
    min-width: 0;
    font-size: 12px;
    line-height: 17px;
    color: #222;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // word-break: break-word;
    strong {
      font-weight: 400;
      color: #C44A01;
    }
  }
  &__contdown {
    line-height: 1;
    margin-top: 2px;
    line-height: 17px;
  }
  &__shipping-operation {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 12px;
    font-size: 12px;
    line-height: 17px;
    color: #222;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
