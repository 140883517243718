<template>
  <div
    :class="[
      'bsc-static-image',
      !disableBottomSpacing ? 'bsc-hasBottomSpace' : '',
      showHotZone ? 'bsc-full-width' : '',
    ]"
  >
    <BaseImg
      v-for="(item, i) in items"
      :key="i"
      v-tap="{
        id: 'click_block_main.comp_top-banner',
        data: { item, index: i, compIndex: index },
      }"
      v-expose="{
        id: 'expose_block_main.comp_top-banner',
        data: { item, index: i, compIndex: index },
      }"
      :fit="'cover'"
      :ratio="item.image && Number(item.image.ratio)"
      :src="baseCutImg(item.image && item.image.src)"
      :dataDesignWidth="2550"
      :ada="item.ada"
      :alt="item.hrefTitle"
      :url="handleGetFullLink(item, i)"
      :firstScreen="true"
      @click="clickItem(item, i)"
    />
    <!-- 热区组件 -->
    <ClientOnly>
      <HotZone
        v-if="showHotZone"
        :index="index"
        :context="context"
        :propData="propData"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { SImage as BaseImg } from '@shein-aidc/sui-image/laptop'
import { commonProps } from '../../common/common-props.js'
import HotZone from './HotZone.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { useLink } from '@shein-aidc/bs-sdk-ccc-link'

const { vTap, vExpose } = inject('analysisInstance')
const cutImg = inject('cutImg', (url) => url)

const props = defineProps({
  ...commonProps,
})

const { cccLink } = useLink({
  propData: props.propData,
  sceneData: {
    sceneName: 'topBanner',
  },
})

const { props: propsData = {}, hotZones = { standard: [] }} = props.propData || {}
const { items = [], metaData = {} } = propsData
const { disableBottomSpacing = true } = metaData

const showHotZone = computed(() => {
  return props.propData?.styleType === 'ONE_IMAGE_COMPONENT' &&
    (hotZones?.standard?.length || hotZones?.timeTransform?.length || hotZones?.label?.length)
})

const baseCutImg = (imgSrc) => {
  // 如果是 webp,不需要裁剪
  if (imgSrc.includes('.webp')) {
    return imgSrc
  }
  if (!cutImg) {
    return imgSrc.replace(/^https?:/, '')
  }
  return cutImg(imgSrc, 2550, { min: 2000, max: 2000 })
}

const handleGetFullLink = (item, i) => {
  return cccLink.getFullLink({
    item,
    compIndex: props.index,
    index: i,
  })
}

const clickItem = (item, i) => {
  const url = handleGetFullLink(item, i)
  if (!url || url.indexOf('javascript:;') > -1) return
  location.href = url
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern */
.bsc-static-image {
  display: flex;
  flex-direction: row;
  // 单图有热区 设置宽高百分百
  &.bsc-full-width {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.bsc-hasBottomSpace {
    padding-bottom: 16px;
    background-color: #fff;
  }
}
</style>

<style lang="less">
.store-page-home--margin {
  .static-image {
    margin-bottom: 20px;
    &.hasBottomSpace {
      padding-bottom: 0px;
    }
  }
}
</style>
