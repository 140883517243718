import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BPersonalCenter } from './personal-center.vue'
import type { DS_PersonalCenter } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getPersonalCenterFsDataRunner(sourceParams?: Partial<DS_PersonalCenter.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
